import { FiHome, FiUsers, FiFolder, FiActivity, FiCalendar } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { SidebarItem } from '.';
import { AppPathsAdmin } from '../../routes/AppRoutes';

export const SidebarAdminItems: React.FC = () => {
    return (
        <>
          <ul className="m-0 px-4 py-6 list-none w-full flex flex-col justify-center md:items-center">
            <SidebarItem label="Home" path={AppPathsAdmin.dashboard} icon={<FiHome className='w-6 h-6' />} />
            <SidebarItem label="Clients" path={AppPathsAdmin.clients} icon={<FiUsers className='w-6 h-6' />} />
            {/* <li className="mb-4">
              <a href="#0" className="flex items-center justify-center w-12 h-12 rounded-md transition-colors bg-gray-50 hover:bg-gray-100">
                <FiCalendar className='w-6 h-6' />
                <span className="sr-only">Home</span>
              </a>
            </li>
            <li className="mb-4">
              <a href="#0" className="flex items-center justify-center w-12 h-12 rounded-md transition-colors bg-gray-50 hover:bg-gray-100">
                <FiFolder className='w-6 h-6' />
                <span className="sr-only">Home</span>
              </a>
            </li>
            <li className="mb-4">
              <a href="#0" className="flex items-center justify-center w-12 h-12 rounded-md transition-colors bg-gray-50 hover:bg-gray-100">
                <FiActivity className='w-6 h-6' />
                <span className="sr-only">Home</span>
              </a>
            </li> */}
          </ul>
        </>
    );
};