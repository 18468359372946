import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiCreateExerciseDto, IApiCreateExerciseVideoDto, IApiExercise, IApiExerciseVideo } from "./models/Exercise";

export const getExercises = async (): Promise<HttpClientResponse<IApiExercise[]>> => {
    const url = buildUrl('/api/exercise');

    const response = await httpClient.getRequest<ApiListResponse<IApiExercise>>(url);
    
    return mapHttpListResponse(response, m => m);
};

export const addExercise = async (exercise: IApiCreateExerciseDto): Promise<HttpClientResponse<IApiExercise>> => {
    const url = buildUrl('/api/exercise');
    
    const response = await httpClient.postRequest<ApiStandardResponse<IApiExercise>>(url, exercise);

    return mapHttpClientResponse(response, m => m);
};

export const editExercise = async (id: string, exercise: IApiExercise): Promise<HttpClientResponse<IApiExercise>> => {
    const url = buildUrl(`/api/exercise/${id}`);
    
    const response = await httpClient.putRequest<ApiStandardResponse<IApiExercise>>(url, exercise);

    return mapHttpClientResponse(response, m => m);
};

export const getExerciseVideos = async (): Promise<HttpClientResponse<IApiExerciseVideo[]>> => {
    const url = buildUrl('/api/video');

    const response = await httpClient.getRequest<ApiListResponse<IApiExerciseVideo>>(url);
    
    return mapHttpListResponse(response, m => m);
};

export const addExerciseVideo = async (exercise: IApiCreateExerciseVideoDto): Promise<HttpClientResponse<IApiExerciseVideo>> => {
    const url = buildUrl('/api/video');
    
    const response = await httpClient.postRequest<ApiStandardResponse<IApiExerciseVideo>>(url, exercise);

    return mapHttpClientResponse(response, m => m);
};