interface AppPageHeaderProps {
    title: string;
    primaryAction?: React.ReactNode;
    secondaryAction?: React.ReactNode;
}

export const AppPageHeader: React.FC<AppPageHeaderProps> = ({
    title,
    primaryAction,
    secondaryAction
}) => {
    return (
        <>
            <div className="bg-white border-b border-gray-200 h-16 w-full flex items-center px-4 mb-4 lg:h-28 xl:px-6">
                <h1 className="font-medium text-2xl lg:text-3xl">{title}</h1>
                <div className="ml-auto flex items-center lg:pr-2">
                    {secondaryAction &&
                        <div className="hidden lg:block lg:mr-4">{secondaryAction}</div>
                    }
                    {primaryAction}
                </div>
            </div>
            {secondaryAction &&
                <div className="w-full px-4 mb-4 lg:hidden">
                    {secondaryAction}
                </div>
            }
        </>
    );
};