import clsx from "clsx";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Button } from "../Buttons";
import { Modal } from "../Modal";

export interface IntervalObject {
    intOn: number;
    intOff: number;
    rounds: number;
}

interface IntervalTimerModalProps {
    interval?: IntervalObject;
    onClose(): void;
}

export const IntervalTimerModal: React.FC<IntervalTimerModalProps> = ({
    interval,
    onClose
}) => {
    const [timerStartDate, setTimerStartDate] = useState<Date | undefined>(new Date());
    const [currentRound, setCurrentRound] = useState(1);
    const [on, setOn] = useState(1);
    const [seconds, setSeconds] = useState<number>();

    const resetTimer = () => {
        setCurrentRound(1);
        setOn(1);
        setSeconds(undefined);
        setTimerStartDate(new Date());
    };

    const handleClose = () => {
        setCurrentRound(1);
        setOn(1);
        setTimerStartDate(undefined);
        setSeconds(undefined);
        onClose();
    };

    useEffect(() => {
        if(timerStartDate && interval) {
            const timeInterval = setInterval(() => {
                const diff = DateTime.fromJSDate(new Date()).diff(DateTime.fromJSDate(timerStartDate)).toFormat("ss");
                const secondsToDisplay = (on % 2 !== 0 ? interval.intOn : interval.intOff) - parseInt(diff);

                setSeconds(secondsToDisplay);
                if(secondsToDisplay === 0) {
                    if(currentRound < interval.rounds) {
                        if(on % 2 === 0) {
                            setCurrentRound(currentRound + 1);
                        }
                    }

                    setOn(on + 1);

                    if(currentRound >= interval.rounds && (on >= (interval.rounds * 2))) {
                        setTimerStartDate(undefined);
                    }
                }
            }, 500);
    
            return () => clearInterval(timeInterval);
        }
    }, [timerStartDate]);

    useEffect(() => {
        setTimerStartDate(new Date());
    }, [interval]);

    useEffect(() => {
        if(on <= ((interval?.rounds ?? 0) * 2)) {
            setTimerStartDate(new Date());
        }
    }, [on]);

    return (
        <>
            <Modal isOpen={Boolean(interval)} closeModal={handleClose}>
                <div className="relative flex flex-col items-center justify-center">
                    <div className="font-medium mb-4">Round {currentRound}/{interval?.rounds}</div>
                    <div className={clsx("w-64 h-64 rounded-full border-8 flex flex-col items-center justify-center", on % 2 === 0 ? "border-red-500 text-red-500" : "border-green-500 text-green-500")}>
                        <div className="text-8xl">{timerStartDate ? (seconds === 0 ? on % 2 === 0 ? interval?.intOff : interval?.intOn : seconds) ?? '--' : '--'}</div>
                        <div className="font-medium text-2xl">{(timerStartDate !== undefined) ? on % 2 === 0 ? "Off" : "On" : ""}</div>
                    </div>
                    <div className="mt-4 w-full">
                        <Button size="small" variant="gray" onClick={resetTimer}>
                            Reset
                        </Button>
                    </div>
                    <div className="mt-4 w-full">
                        <Button size="small" variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};