import clsx from "clsx";
import { Image } from '../Image';
import { useAuth } from "../../auth/AuthContext";
import { config } from "../../config";

interface AvatarThirdPartyProps {
    size?: "sm" | "xxs";
    initials: string;
    src?: string;
}

export const AvatarThirdParty: React.FC<AvatarThirdPartyProps> = ({
    size,
    initials,
    src
}) => {
    const { apiUri } = config;
    
    return (
        <div className={clsx(
            size === "sm" && "w-12 h-12 text-lg",
            size === "xxs" && "w-6 h-6 text-xxs",
            !size && "w-24 h-24 text-3xl",
            "rounded-full bg-blue-50 flex items-center justify-center font-medium text-blue-400 overflow-hidden"
            )}>
            {initials && !src &&
                <span>{initials}</span>
            }
            {src &&
                <Image src={`${apiUri}/api/file/${src}`} className="w-full h-full object-cover pointer-events-none" />
            }
        </div>
    );
};