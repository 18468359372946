import clsx from "clsx";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { AdminClientDetailPaths, ClientDetailRouter, ClientDetailSkeleton } from ".";
import { getVendorClient } from "../../../api/clients";
import { AvatarThirdParty } from "../../../components/Avatar";
import { useSticky } from "../../../hooks/useSticky";
import { AppPathsAdmin } from "../../../routes/AppRoutes";
import { AppPageLayout } from "../../PageLayouts"

interface PageParams {
    id: string;
    month?: string;
    year?: string;
}

export const ClientDetailPage: React.FC = () => {
    const [stickyHeader, sticky] = useSticky<HTMLDivElement>();
    const { id, month, year } = useParams<PageParams>();
    const { isLoading, error, data: client, refetch } = useQuery(`clientDetail/${id}`, async () => { 
        const result = await getVendorClient(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    const initials = `${client?.firstname[0]}${client?.lastname[0]}`;

    const dt = DateTime.now();
    const currentMonth = month ?? dt.toFormat("MM");
    const currentYear = year ?? dt.toFormat("yyyy");

    const documentTitle = client ? `${client?.firstname} ${client?.lastname}` : "Clients";
    
    return (
        <AppPageLayout title={documentTitle}>
            <div className="">
                {isLoading &&
                    <ClientDetailSkeleton />
                }
                {!isLoading && client &&
                <>
                <div ref={stickyHeader} className="overflow-hidden bg-white flex flex-col shadow-soft mb-6 border-b border-gray-200 sticky top-12 z-1">
                    <div className={clsx("flex items-center flex-wrap md:flex-nowrap border-b border-gray-200 p-8", sticky && "pt-12 pb-4")}>
                        <div className="mx-auto w-full max-w-screen-2xl flex items-center">
                            <AvatarThirdParty initials={initials} src={client.avatar} size="sm" />
                            <h1 className={clsx("font-medium ml-4", sticky ? "text-2xl" : "text-3xl")}>{client.firstname} {client.lastname}</h1>
                        </div>
                    </div>
                    <div className="mx-auto w-full max-w-screen-2xl flex items-center">
                        <div className="flex 2xl:border-l 2xl:border-gray-200">
                            <NavLink exact strict to={AppPathsAdmin.clientDetail(id)} className="px-6 py-4 bg-white font-medium border-r border-gray-200 transition-all hover:bg-gray-50" activeClassName="bg-blue-400 hover:bg-blue-500 text-white">Overview</NavLink>
                            <NavLink exact strict to={AdminClientDetailPaths.program(id)} className="px-6 py-4 bg-white font-medium border-r border-gray-200 transition-all hover:bg-gray-50" activeClassName="bg-blue-400 hover:bg-blue-500 text-white">Program</NavLink>
                            <NavLink exact to={AdminClientDetailPaths.logbook(id, currentMonth, currentYear)} className="px-6 py-4 bg-white font-medium border-r border-gray-200 transition-all hover:bg-gray-50" activeClassName="bg-blue-400 hover:bg-blue-500 text-white">Logbook</NavLink>
                        </div>
                    </div>
                </div>
                <div className="mx-auto w-full px-6">
                    <ClientDetailRouter client={client} />
                </div>
                </>
                }
            </div>
        </AppPageLayout>
    );
};