import clsx from "clsx";

interface SteppedContentProps {
    isActive: boolean;
    label: string;
}

export const SteppedContent: React.FC<SteppedContentProps> = ({
    isActive,
    label,
    children
}) => {
    return (
        <>
            <div className={clsx("flex flex-none py-4 px-8 border-b border-gray-200")}>
                <h2 className={clsx("text-2xl font-medium text-gray-600", !isActive && "opacity-60")}>{label}</h2>
            </div>
            <div className={clsx("h-full p-6 border-b border-gray-200", !isActive && "hidden")}>
                 {children}
            </div>
        </>
    );
};