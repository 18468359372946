import { Fragment } from "react";
import { useAuth } from "../../auth/AuthContext";
import { Popover, Transition } from '@headlessui/react';
import { FiUser, FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AppPathsClient } from "../../routes/AppRoutes";
import { Avatar } from "../Avatar";

export const HeaderAvatarDropdown: React.FC = () => {
    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button>
                        <Avatar size="sm" />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        >
                        <Popover.Panel className="absolute z-10 w-screen max-w-xs mt-3 right-0">
                            <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white p-7">
                                <Link
                                    to={AppPathsClient.profile}
                                    className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50"
                                >
                                    <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-blue-100 transition-colors">
                                        <FiUser className="w-6 h-6 text-blue-500" />
                                    </div>
                                    <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                            My Profile
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            Manage your profile
                                        </p>
                                    </div>
                                </Link>
                                <a
                                    href='#0'
                                    className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50"
                                >
                                    <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-blue-100 transition-colors">
                                        <FiSettings className="w-6 h-6 text-blue-500" />
                                    </div>
                                    <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                            Settings
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            Modify your settings
                                        </p>
                                    </div>
                                </a>
                            </div>
                            </div>
                        </Popover.Panel>
                        </Transition>
                </>
            )}
        </Popover>
    );
};