import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { MainContent } from "../../components/MainContent";
import { Sidebar } from "../../components/Sidebar";
import useDocumentTitle from "../../hooks/useDocumentTitle";

interface AppPageLayoutProps {
    title?: string;
}

export const AppPageLayout: React.FC<AppPageLayoutProps> = ({
    title,
    children
}) => {
    useDocumentTitle(title);
    return (
        <>
            <Sidebar />
            <Header />
            <MainContent>
                {children}
            </MainContent>
        </>
    );
};