import clsx from 'clsx';
import { FiLogOut, FiX } from 'react-icons/fi';
import { SidebarAdminItems, SidebarClientItems } from '.';
import LogoCircle from '../../assets/svg/logo-circle.svg';
import { useAuth } from '../../auth/AuthContext';
import { ROLES } from '../../routes/AppRoutes';
import { useUI } from '../../ui/UIContext';
import { IconButton } from '../Buttons';

export const Sidebar: React.FC = () => {
  const { logOut, user } = useAuth();
  const { navIsOpen, setNavIsOpen } = useUI();

  const isAdmin = (user?.role === ROLES.COACH || user?.role === ROLES.SUPER);
  const isClient = user?.role === ROLES.CLIENT;
  
    return (
      <>
        <div className={clsx(
          "fixed top-0 left-0 right-0 bottom-0 inset-0 bg-gray-800 bg-opacity-80 z-20 transition-all md:hidden",
          navIsOpen && "opacity-1 pointer-events-auto", 
          !navIsOpen && "opacity-0 pointer-events-none"
          )} onClick={() => setNavIsOpen(false)}></div>
        <aside className={clsx(
          "fixed flex flex-col top-0 md:left-0 h-screen w-full max-w-xs md:max-w-auto md:w-24 border-r bg-white border-gray-200 z-20 transition-all",
          !navIsOpen && "-right-full",
          navIsOpen && "right-0")}>
          <a href="#0" className="hidden md:flex justify-center items-center h-20">
            <img src={LogoCircle} alt="PT Sphere Logo" className="w-12 h-12" />
          </a>
          <div className="flex p-4 border-b items-center justify-between bg-blue-400 border-gray-200 md:hidden">
            <span className="font-medium text-white">Menu</span>
            <IconButton label='Close menu' onClick={() => setNavIsOpen(!navIsOpen)}>
              <FiX className="text-white" />
            </IconButton>
          </div>
          {isAdmin && <SidebarAdminItems />}
          {isClient && <SidebarClientItems />}
          <div className="flex items-center md:justify-center pb-4 mt-auto bg-red-50 pt-4 px-4 md:bg-transparent">
            <button className="flex items-center" onClick={logOut}>
              <div className="flex items-center justify-center w-12 h-12 rounded-md transition-colors bg-red-400 md:bg-gray-50 hover:bg-red-100">
                <FiLogOut className='w-6 h-6' />
              </div>
              <span className="ml-4 font-medium md:sr-only">Logout</span>
            </button>
          </div>
        </aside>
      </>
    );
};