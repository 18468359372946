import { createContext, useContext } from "react";
import { IApiExercise, IApiExerciseVideo } from "../api/models/Exercise";

export interface IExerciseContext {
    exerciseList: IApiExercise[];
    exerciseVideoList: IApiExerciseVideo[];
    isLoading: boolean;
    getExerciseById: (exerciseId: string) => IApiExercise | null;
    getExerciseVideoById: (exerciseId: string) => IApiExerciseVideo | null;
    refetch: () => void;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const ExerciseContext = createContext<IExerciseContext>({
    exerciseList: [],
    exerciseVideoList: [],
    isLoading: false,
    getExerciseById: placeholderFunc,
    getExerciseVideoById: placeholderFunc,
    refetch: placeholderFunc
});

export const useExercise: () => IExerciseContext = () => useContext(ExerciseContext);