import { FiUserPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { UserGridItemMenu } from '.';
import { IUser } from '../../models/User';
import { AvatarThirdParty } from '../Avatar';

interface UserGridProps {
    users: IUser[];
    addAction?: () => void;
    refetch(): void;
}

export const UserGrid: React.FC<UserGridProps> = ({
    users,
    addAction,
    refetch
}) => {
    return (
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {users.map(user =>
                <Link to={`/clients/${user._id}`} key={user._id} className="rounded-md h-44 sm:h-56 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1">
                    <AvatarThirdParty initials={`${user.firstname[0]}${user.lastname[0]}`} src={user.avatar} />
                    <div className="font-medium mt-4">{user.firstname} {user.lastname}</div>
                    <div className="absolute top-4 right-4 w-6 h-6 flex items-center justify-center">
                        <UserGridItemMenu userId={user._id} refetch={refetch} />
                    </div>
                </Link>
            )}
            {addAction &&
                <button className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100" onClick={addAction}>
                    <FiUserPlus className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
                    <span className="sr-only">Add Client</span>
                </button>
            }
        </div>
    );
};