import { HTMLProps } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputError } from "../Inputs";

interface SelectOption {
    value: string | number,
    label: string
}

interface SmartSelectProps {
  register: UseFormRegisterReturn;
  label?: string;
  error?: string;
  defaultOption?: string;
  options: SelectOption[];
}

export function SmartSelect({
  register,
  label,
  options,
  error,
  defaultOption,
  ...props
}: Partial<SmartSelectProps & HTMLProps<HTMLSelectElement>>): JSX.Element {
    const id = `${props.name}-${label?.replace(' ', '')}`;

    return (
        <div className="w-full">
            {label &&
                <label htmlFor={id} className="block text-sm font-medium text-black mb-1">{label}</label>
            }
            {register && options &&
                <select className="h-12 w-full border border-gray-200 px-2 rounded-md" defaultValue='-1' {...register} {...props}>
                    <option value='-1' disabled>{defaultOption ?? 'Choose option'}</option>
                    {options.map((option, index) => 
                        <option value={option.value} key={index}>{option.label}</option>
                    )}
                </select>
            }
            {error && <InputError error={error} />}
        </div>
    );
}