import { useRef, useEffect } from 'react'

function useDocumentTitle(title?: string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const prefix = `PTSPHERE | `;

  useEffect(() => {
      if(title) {
        document.title = `${prefix}${title}`;
      }

      if(!title) {
          document.title = 'PTSPHERE';
      }
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle