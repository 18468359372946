import { Dialog, Popover, Transition } from '@headlessui/react';
import { debounce } from "lodash";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useProgramEditor } from "../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { Button, IconButton } from "../Buttons";
import clsx from 'clsx';
import { RelativeLoader } from '../Loader';
import { FiPlus, FiSearch, FiTrash2, FiVideo, FiX } from 'react-icons/fi';
import { useExercise } from '../../exercise/ExerciseContext';
import { AddExerciseForm } from '../../forms/AddExerciseForm';
import { MdFitnessCenter } from 'react-icons/md';
import { EditExerciseModal } from './EditExerciseModal';
import { ExercisePickerItem } from './ExercisePickerItem';
import { IApiExercise } from '../../api/models/Exercise';

interface ExercisePickerProps {
    open: boolean;
    onMakeSelection: (selection: string[]) => void;
}

export const ExercisePicker: React.FC<ExercisePickerProps> = ({
    open,
    onMakeSelection
}) => {
    const { exerciseList, getExerciseById } = useExercise();
    
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [selectedExercises, setSelectedExercises] = useState<string[]>([]);
    const [editExercise, setEditExercise] = useState<string>();
    const [exercises, setExercises] = useState<IApiExercise[]>([]);

    // const exercises = searchTerm ? exerciseList.filter(exercise => exercise.name.toLowerCase().includes(searchTerm.toLowerCase())) : [];

    const debouncedFilter = useRef(
        debounce(async (searchTerm) => {
            const exercises = searchTerm ? exerciseList.filter(exercise => exercise.name.toLowerCase().includes(searchTerm.toLowerCase())) : [];
            setExercises(exercises);
            setIsSearching(false);
        }, 300)
      ).current;

    const handleExerciseSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value);
        setIsSearching(true);
        debouncedFilter(e.currentTarget.value);
    };

    const handleClose = () => {
        setSearchTerm("");
        setSelectedExercises([]);
        setExercises([]);
        onMakeSelection([]);
    };

    const handleExerciseSelection = (exerciseId: string) => {
        let selected = [...selectedExercises];

        if(selected.includes(exerciseId)) {
            selected = selected.filter(exercise => exercise !== exerciseId);
        } else {
            selected.push(exerciseId);
        }

        setSelectedExercises(selected);
    };

    const handleAddSelectedExercises = () => {
        const selected = [...selectedExercises];
        setSelectedExercises([]);
        onMakeSelection(selected);
    };

    const handleCancel = () => {
        setSearchTerm("");
        setSelectedExercises([]);
        setExercises([]);
        onMakeSelection([]);
    };

    const clearSearch = () => {
        setSearchTerm('');
        setExercises([]);
    };

    useEffect(() => {
        return () => {
          debouncedFilter.cancel();
          setIsSearching(false);
        };
    }, [debouncedFilter]);

    return (
        <>
        <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto bg-gray-800 bg-opacity-80"
                    onClose={handleClose}
                    >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full max-w-3xl my-8 text-left align-middle transition-all transform">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="bg-white shadow-xl rounded-md p-6 col-span-2 flex flex-col min-h-screen-padded max-h-screen-padded">
                                <div className="flex-none">
                                    <div className='flex items-center'>
                                        <div className="text-xl font-medium leading-6 text-gray-900">
                                            Exercise library
                                        </div>
                                        <div className='ml-auto'>
                                            <IconButton label='Add exercise' onClick={() => setEditExercise("")} tabIndex={-1}>
                                                <FiPlus />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="relative flex-none w-full mt-4 border-gray-300 md:w-auto">
                                        <input type="text" placeholder="Search exercises..." className="h-12 w-full border border-gray-200 pl-4 pr-12 rounded-md" value={searchTerm} onChange={handleExerciseSearch} />
                                        {searchTerm === '' &&
                                            <div className="absolute right-0 top-0 w-12 h-full flex items-center justify-center">
                                                <FiSearch className="w-4 h-4" />
                                            </div>
                                        }
                                        {searchTerm !== '' &&
                                            <button className="absolute right-0 top-0 w-12 h-full flex items-center justify-center" onClick={clearSearch}>
                                                <FiX className="w-4 h-4" />
                                                <span className="sr-only">Clear exercise search input</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                                
            <div className="flex-1 mt-6 overflow-y-auto flex flex-col">
                {exercises.map((exercise, i) =>
                    <ExercisePickerItem 
                        key={exercise._id} 
                        exercise={exercise} 
                        isSelected={selectedExercises.includes(exercise._id)} 
                        onClick={handleExerciseSelection}
                        onEdit={setEditExercise} />
                )}
                {exercises.length === 0 && searchTerm !== "" && !isSearching &&
                    <div className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 h-16 sm:h-20 rounded-md p-2 overflow-hidden">
                        <div className="text-center">No exercises found matching <b>{searchTerm}</b></div>
                    </div>
                }
                {exercises.length === 0 && searchTerm === "" && !isSearching &&
                    <div className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 h-16 sm:h-20 rounded-md p-2 overflow-hidden">
                        <div className="text-center">Search for exercises using the search box above</div>
                    </div>
                }
                {isSearching &&
                    <RelativeLoader />
                }
                {selectedExercises.length > 0 && searchTerm === "" &&
                    <div className="mt-auto">
                        <div className='font-medium leading-6 text-gray-900'>Selected</div>
                        {selectedExercises.map((selectedExercise) => {
                            const exercise = getExerciseById(selectedExercise);

                            if(!exercise) return null;

                            return (
                                <div 
                                    key={exercise._id} 
                                    className={clsx(
                                        "flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50 mb-2",
                                        selectedExercises.includes(exercise._id) && "bg-blue-200 hover:bg-blue-300")} 
                                    onClick={() => handleExerciseSelection(exercise._id)}>
                                    <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                                        <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                                    </div>
                                    <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900 pointer-events-none">{exercise.name}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
                </div>
                <div className='flex-none grid grid-cols-2 gap-4 mt-4'>
                    <Button variant='gray' onClick={handleCancel}>Cancel</Button>
                    <Button disabled={selectedExercises.length === 0} onClick={handleAddSelectedExercises}>Add Selection {selectedExercises.length > 0 && `(${selectedExercises.length})`}</Button>
                </div>
            </div>
                                        </div>
                        </div>
                        </Transition.Child>
                    </div>
                    <EditExerciseModal open={editExercise !== undefined} exercise={editExercise ?? ""} onClose={() => setEditExercise(undefined)} />
                    </Dialog>
                </Transition>
        </>
    );
};