import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { addVendorClient } from "../../api/clients";
import { Button } from "../../components/Buttons";
import { SmartInput } from "../../components/SmartForm";
import { toastNotification } from "../../components/ToastNotification";

export interface AddClientFormInputs {
    firstname: string;
    lastname: string;
    email: string;
}

interface AddClientFormProps {
    onCancel?: () => void
}

const schema = yup.object({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    email: yup.string().email('Please enter a valid email address').required('Email address is required'),
}).required();

export const AddClientForm: React.FC<AddClientFormProps> = ({
    onCancel
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<AddClientFormInputs>({
        resolver: yupResolver(schema)
    });

    const createSuccessMessage = (email: string) => {
        return `An invitation has been sent to ${email}`;
    };

    const onSubmit = async (values: AddClientFormInputs) => {
        const result = await addVendorClient(values);

        if(!result.isError) {
            toastNotification({ variant: 'success', title: 'Client added', message: createSuccessMessage(values.email) });
            onCancel && onCancel();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="grid gap-4 flex-1">
                <SmartInput register={register('firstname')} placeholder='First name' label='First name' error={errors.firstname?.message} />
                <SmartInput register={register('lastname')} placeholder='Last name' label='Last name' error={errors.lastname?.message} />
                <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
            </div>
            <div className='mt-4 grid grid-cols-2 gap-4'>
                {onCancel ?
                    <Button type='button' variant='transparent' onClick={onCancel}>Cancel</Button>
                    :
                    <div></div>
                }
                <Button type='submit'>Add client</Button>
            </div>
        </form>
    );
};