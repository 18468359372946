import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import * as yup from 'yup';
import queryString from 'query-string';
import { ProgramType } from "../../api/models/Program";
import { createWorkoutProgram } from "../../api/program";
import { Button } from '../../components/Buttons';

import { SmartInput, SmartTextArea } from "../../components/SmartForm";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { toastNotification } from "../../components/ToastNotification";
import { AppPathsAdmin } from "../../routes/AppRoutes";
import { useQuery } from "react-query";
import { getVendorClients } from "../../api/clients";
import { SmartDateInput } from "../../components/SmartForm/SmartDateInput";
import { DateTime } from "luxon";

export interface CreateProgramFormInputs {
    client?: string;
    name: string;
    description?: string;
    type: ProgramType;
    template: boolean;
    sync: boolean;
    startDate: string;
}

interface QueryParams {
    client?: string;
}

const schema = yup.object({
    client: yup.string().notRequired(),
    name: yup.string().required('Program name is required'),
    description: yup.string().notRequired(),
    type: yup.string().oneOf([ProgramType.WEEKLY, ProgramType.CYCLE]).required('Program type is required'),
    template: yup.boolean(),
    sync: yup.boolean(),
    startDate: yup.string().required('Please select a start date')
});

const programTypeOptions = [
    {
        label: 'Weekly',
        value: 'weekly',
        description: 'A weekly based workout schedule running Mon - Sun'
    },
    {
        label: 'Cycle based',
        value: 'cycle',
        description: 'A cycle based workout schedule (e.g. 3 days on, 1 day off)'
    }
]

export const CreateProgramForm: React.FC = () => {
    const history = useHistory();
    const { client }: QueryParams = queryString.parse(location.search);
    const [programType, setProgramType] = useState<ProgramType>(ProgramType.WEEKLY);

    const { isLoading, error, data: clients = [], refetch } = useQuery('clientList', async () => { 
        const result = await getVendorClients();
        if(!result.isError) {
            return result.content;
        }   
    });

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<CreateProgramFormInputs>({
        resolver: yupResolver(schema)
    });

    if(client) {
        setValue('client', client);
    }

    const handleProgramTypeChange = (val: ProgramType) => {
        setProgramType(val);
        setValue('type', val);
    };

    const onSubmit = async (values: CreateProgramFormInputs) => {
        values.startDate = DateTime.fromISO(values.startDate).set({ hour: 0 }).toUTC().toString();
        const result = await createWorkoutProgram(values);
    
        if(result.isError) {
            toastNotification({ variant: 'error', title: 'Error creating workout program', message: result.message });
        }

        if(!result.isError) {
            toastNotification({ variant: 'info', title: 'Workout program created', message: `${values.name} has been created` });
            history.push(AppPathsAdmin.workoutPlanEdit(result.content._id));
        }
    };

    setValue('type', programType);

    const clientDetail = client && clients.find(c => c._id === client);
    const initials = clientDetail &&`${clientDetail?.firstname[0]}${clientDetail?.lastname[0]}`; 

    return (
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            {clientDetail && <>
                <h2 className="text-xl font-medium mb-4">Client:</h2>
                <div className="inline-flex items-center bg-white rounded-md shadow-soft px-5 py-4 mb-8">
                    <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden">
                        <div className="w-full h-full flex items-center justify-center text-gray-400 font-medium">
                            <span>{initials}</span>
                        </div>
                    </div>
                    <div className="font-medium ml-4">{clientDetail.firstname} {clientDetail.lastname}</div>
                </div>
            </>}
            <h2 className="text-xl font-medium mb-4">Program details:</h2>
            <div className="rounded-md overflow-hidden bg-white shadow-soft mb-8 p-6 grid grid-cols-2 gap-4">                
                <div className="flex flex-col">
                    <SmartInput register={register('name')} label='Program name' placeholder='Program name' error={errors.name?.message} />
                    <div className="mt-4">
                        <SmartTextArea register={register('description')} label='Description / notes' placeholder='Useful notes for your client' error={errors.description?.message} />
                    </div>
                </div>
                <div className="w-full mb-4">
                    <SmartDateInput 
                        label='Start date' 
                        register={register('startDate')}
                        value={getValues('startDate')} 
                        error={errors.startDate?.message} />
                </div>
            </div>
            <h2 className="text-xl font-medium mb-4 hidden">Schedule type:</h2>
            <SmartRadioGroup options={programTypeOptions} value={programType} onChange={(val: any) => handleProgramTypeChange(val as ProgramType)} />
            <input type='hidden' value="false" {...register('template')} />
            <input type='hidden' value="false" {...register('sync')} />
            <hr className="py-4" />
            <div className="flex justify-items-end pb-4">
                <div className="flex-none ml-auto">
                    <Button>Next</Button>
                </div>
            </div>
        </form>
    );
};