import { FiWind } from "react-icons/fi";
import { Button } from "../../../../components/Buttons";
import { AppPathsAdmin } from "../../../../routes/AppRoutes";

export const ClientLogbookEmptyState: React.FC = () => {
    return (
        <div className="p-6 flex flex-col items-center justify-center h-96 text-center">
            <FiWind className="w-12 h-12 mb-4 text-gray-600 -mt-32" />
            <h1 className="text-2xl font-medium text-gray-700 mb-2">No logs found</h1>
            <p className="mb-6">Client has no logs for the selected date period</p>
            <div>
                <Button link={AppPathsAdmin.dashboard}>View dashboard</Button>
            </div>
        </div>
    );
}