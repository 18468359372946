import { createContext, useContext } from "react";

export interface IUIContext {
    navIsOpen: boolean;
    setNavIsOpen: (isOpen: boolean) => void;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const UIContext = createContext<IUIContext>({
    navIsOpen: false,
    setNavIsOpen: placeholderFunc
});

export const useUI: () => IUIContext = () => useContext(UIContext);