import { NavLink } from "react-router-dom";

interface SidebarItemProps {
    label: string;
    icon: React.ReactNode;
    path: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
    label,
    icon,
    path
}) => {
    return (
        <li className="mb-4 flex items-center">
            <NavLink exact to={path} activeClassName="bg-blue-400 text-white hover:bg-blue-200 sidebar-item-active" className="relative flex items-center justify-center w-12 h-12 rounded-md transition-colors bg-gray-50 hover:bg-gray-100">
                {icon}
                <span className="absolute left-full ml-4 font-medium md:sr-only">{label}</span>
            </NavLink>
        </li>
    );
};