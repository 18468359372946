import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { MainContent } from "../../../components/MainContent";
import { Sidebar } from "../../../components/Sidebar";
import { FiPlus, FiSearch, FiUserPlus, FiX } from 'react-icons/fi';
import { UserGrid, UserGridSkeleton } from "../../../components/UserGrid";
import { getVendorClients } from "../../../api/clients";
import { useEffect, useState } from "react";
import { IUser } from "../../../models/User";
import { Modal } from "../../../components/Modal";
import { AddClientForm } from "../../../forms/AddClientForm";
import { useQuery } from "react-query";
import { AppPageLayout } from "../../PageLayouts";
import { AppPageHeader } from "../../../components/AppPageHeader";
import { IconButton } from "../../../components/Buttons";

export const ClientsPage: React.FC = () => {
    const [clientSearchTerm, setClientSearchTerm] = useState<string>('');
    const [addClientModal, setAddClientModal] = useState(false);
    const { isLoading, error, data: clients = [], refetch } = useQuery('clientList', async () => { 
        const result = await getVendorClients()
        if(!result.isError) {
            return result.content;
        }   
    });

    const handleAddClientModalClose = () => {
        refetch();
        setAddClientModal(false);
    };

    const filteredClients = clients && clientSearchTerm && clientSearchTerm.length > 0 ? clients.filter(client => `${client.firstname.toLowerCase()} ${client.lastname.toLowerCase()}`.includes(clientSearchTerm?.toLowerCase())) : clients;

    const AddClientButton = (
        <IconButton label='Add client' onClick={() => setAddClientModal(true)}>
                <FiPlus />
        </IconButton>
    );

    const SearchClientInput = (
        <div className="relative flex-none w-full border-gray-300 lg:w-auto">
            <input value={clientSearchTerm} onChange={(e) => setClientSearchTerm(e.currentTarget.value)} type="text" placeholder="Search clients..." className="h-12 w-full border border-gray-200 pl-4 pr-12 rounded-md lg:w-64" />
            {clientSearchTerm === '' &&
                <div className="absolute right-0 top-0 w-12 h-full flex items-center justify-center">
                    <FiSearch className="w-4 h-4" />
                </div>
            }
            {clientSearchTerm !== '' &&
                <button className="absolute right-0 top-0 w-12 h-full flex items-center justify-center" onClick={() => setClientSearchTerm('')}>
                    <FiX className="w-4 h-4" />
                    <span className="sr-only">Clear client search input</span>
                </button>
            }
        </div>
    );

    return (
        <>
            <AppPageLayout title='Clients'>
                <AppPageHeader 
                    title="Clients" 
                    primaryAction={AddClientButton}
                    secondaryAction={SearchClientInput} />
                <div className="px-4 pt-4 xl:px-6">
                    {isLoading &&
                        <UserGridSkeleton />
                    }
                    {filteredClients.length > 0 &&
                        <UserGrid users={filteredClients} addAction={() => setAddClientModal(true)} refetch={refetch} />
                    }
                    {!isLoading && filteredClients.length === 0 && clientSearchTerm !== '' &&
                        <div className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 h-44 sm:h-56 rounded-md">
                            <div className="">No clients found matching <b>{clientSearchTerm}</b></div>
                        </div>
                    }
                    {!isLoading && filteredClients.length === 0 && clientSearchTerm === '' &&
                        <button className="group w-full flex flex-col items-center justify-center border-dashed border-2 border-gray-200 h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100"  onClick={() => setAddClientModal(true)}>
                            <FiUserPlus className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
                            <span className="font-medium mt-4 text-lg text-gray-300 transition-colors group-hover:text-blue-400">Add Client</span>
                        </button>
                    }
                    <Modal 
                        isOpen={addClientModal} 
                        closeModal={handleAddClientModalClose}
                        title='Add a client'
                        caption='Enter your clients details below to invite them to join you on your PT Sphere platform.'>
                        <AddClientForm onCancel={handleAddClientModalClose} />
                    </Modal>
                </div>
            </AppPageLayout>
        </>
    );
};