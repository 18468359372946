import clsx from "clsx";
import { HTMLProps } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputError } from "../Inputs";

interface SmartInputProps {
  register: UseFormRegisterReturn;
  label?: string;
  error?: string;
}

export function SmartInput({
  register,
  label,
  error,
  ...props
}: Partial<SmartInputProps & HTMLProps<HTMLInputElement>>): JSX.Element {
    const id = `${props.name}-${label?.replace(' ', '')}`;

    return (
        <div className="w-full">
            {label &&
                <label htmlFor={id} className="block text-sm font-medium text-black mb-1">{label}</label>
            }
            {register &&
                <input id={id} className={clsx(
                  "h-12 w-full border border-gray-200 px-2 rounded-md",
                  error && "border-2 border-red-500 outline-none"
                )} {...register} {...props} />
            }
            {error && <InputError error={error} />}
        </div>
    );
}