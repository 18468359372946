import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Button } from "../../components/Buttons";
import { InputError } from "../../components/Inputs";
import { SmartInput } from "../../components/SmartForm";

export interface ClientConfirmationFormInputs {
    password: string;
    confirmPassword: string;
}

interface ClientConfirmationFormProps {
    error?: string,
    isReset: boolean,
    onSubmit: (values: ClientConfirmationFormInputs) => void
}

const schema = yup.object({
    password: yup.string().min(8, 'Password  must be at least 8 characters long').required('Please enter a password'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
}).required();

export const ClientConfirmationForm: React.FC<ClientConfirmationFormProps> = ({
    error,
    isReset,
    onSubmit
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<ClientConfirmationFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <h1 className="text-3xl font-medium mb-12">{isReset ? 'Reset your password' : 'Choose your password'}</h1>
            <div className="relative w-full max-w-sm">
                <SmartInput register={register('password')} type='password' placeholder='Password' label='Password' error={errors.password?.message} />
                <br />
                <SmartInput register={register('confirmPassword')} type='password' placeholder='Confirm password' label='Confirm password' error={errors.confirmPassword?.message} />
            </div>
            <div className='mt-4 w-full max-w-sm'>
                <Button type='submit'>{isReset ? 'Reset' : 'Complete sign-up'}</Button>
                {error && <div className="mt-4">
                    <InputError error={error} />
                </div>}
            </div>
        </form>
    );
};