import { Loader } from ".";

interface RelativeLoaderProps {
    size?: "sm"
}

export const RelativeLoader: React.FC<RelativeLoaderProps> = ({
    size
}) => {
    return (
        <div className="relative w-full h-full flex items-center justify-center">
            <Loader variant='Primary' size={size} />
        </div>
    );
};