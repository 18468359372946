export enum ROLES {
    CLIENT,
    COACH,
    SUPER
}

export enum GENDER {
    MALE = 1,
    FEMALE = 2
}

export interface IUser {
    _id: string,
    email: string;
    phone?: string;
    firstname: string;
    lastname: string;
    createdAt: Date;
    updatedAt: Date;
    vendorId: string;
    role: ROLES;
    dob?: string;
    gender?: GENDER;
    avatar?: string;
    confirmationCode?: string;
}