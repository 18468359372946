import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { ActivityItem } from ".";
import { getLatestActivity } from "../../api/activity";

export const ActivityCard: React.FC = () => {
    const [fetchDateTime, setFetchDateTime] = useState<string>();
    const { isLoading, error, data: activities = [], refetch } = useQuery('recent-activity', async () => { 
        const result = await getLatestActivity()
        if(!result.isError) {
            setFetchDateTime(DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_WITH_SECONDS));
            return result.content;
        }   
    });

    return (
        <div className="lg:h-full-minus-header flex flex-col rounded-md bg-white">
            <div className="w-full p-6 border-b border-gray-100">
                <h3 className="text-blue-400 font-medium text-lg">Activity</h3>
            </div>
            <div className="flex-1 flex flex-col px-0 py-0">
                {!isLoading && activities.length === 0 &&
                    <div className="px-6 py-4 font-medium text-gray-500">No recent activity</div>
                }
                {!isLoading && activities.length > 0 &&
                    <ul className="m-0 p-0 list-none">
                        {activities.map(activity =>
                            <ActivityItem activity={activity} key={activity._id} />
                        )}
                    </ul>
                }
                <div className="mt-auto border-t border-gray-100 p-6">
                    <div className="text-xxs font-medium text-gray-400 text-center">Last updated {fetchDateTime?.toString()}</div>
                </div>
            </div>
        </div>
    );
};