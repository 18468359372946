import clsx from "clsx";
import { useEffect } from "react";
import { FiChevronLeft, FiChevronRight, FiClock } from "react-icons/fi";
import { MdFitnessCenter } from "react-icons/md";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getClientProgramDay } from "../../../api/program";
import { AppPageHeader } from "../../../components/AppPageHeader";
import { FullScreenLoader } from "../../../components/Loader/FullScreenLoader";
import { WorkoutLoggerSet, WorkoutLoggerStepMenu } from "../../../components/WorkoutLogger";
import { useExercise } from "../../../exercise/ExerciseContext";
import { useWorkout } from "../../../workout/WorkoutContext";
import { WorkoutNav } from "../../../workout/WorkoutProvider";
import { AppPageLayout } from "../../PageLayouts";

interface Params {
    id: string;
    step: string;
}

export const WorkoutPage: React.FC = () => {
    const { id, step } = useParams<Params>();
    const { getExerciseById } = useExercise();
    const { initializeWorkout, navigateWorkout, saveProgramLog, timer } = useWorkout();
    const activeStep = parseInt(step);

    const { isLoading, error, data: programDay, refetch } = useQuery(`workout-${id}`, async () => { 
        const result = await getClientProgramDay(id)
        if(!result.isError) {
            return result.content;
        }   
    });

    useEffect(() => {
        if(!timer && programDay) {
            initializeWorkout(id, programDay.workoutSteps.filter(workoutStep => workoutStep.parentStepId === undefined).length, activeStep);
        }
    }, [programDay]);

    const workoutStep = programDay?.workoutSteps.filter(workoutStep => workoutStep.parentStepId === undefined)[activeStep];
    const exercise = getExerciseById(workoutStep?.exercise ?? '');
    const isLastStep = (activeStep + 1) === programDay?.workoutSteps.filter(workoutStep => !workoutStep.parentStepId).length;
    const supersetSteps = programDay?.workoutSteps.filter(step => step.parentStepId === workoutStep?.workoutStepId);
    const exercises = workoutStep ? [workoutStep?.exercise] : [];
    supersetSteps && supersetSteps.map(e => exercises.push(e.exercise));

    return (
        <AppPageLayout title={programDay?.name ?? 'Workout'}>
            {isLoading && <FullScreenLoader />}
            {!isLoading && programDay &&
                <div className="flex flex-col w-full pb-8">
                    <div className="flex-1">
                        <AppPageHeader title={programDay.name ?? 'Workout'} primaryAction={
                            <div className="ml-auto flex items-center justify-center bg-blue-400 text-white font-medium h-12 w-36 rounded-md px-4">
                                <FiClock className="w-6 h-6 mr-3" />
                                <span>{timer}</span>
                            </div>
                        } />
                        <div className="p-6">
                            {workoutStep &&
                            <>
                                <div className={clsx("relative p-4 rounded-md group bg-white mb-4", supersetSteps && supersetSteps.length > 0 && "pl-8")}>
                                    <div className="flex items-center">
                                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                                            <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                                        </div>
                                        <div className="ml-4 flex-1">
                                            <p className="font-medium text-gray-900">{exercise?.name}</p>
                                        </div>
                                        <div className="flex-none flex items-center">
                                            <WorkoutLoggerStepMenu activeStep={activeStep} exercises={exercises} />
                                        </div>
                                    </div>
                                    {workoutStep.sets.map(workoutSet =>
                                        <WorkoutLoggerSet 
                                            workoutSet={workoutSet}
                                            workoutStepId={workoutStep.workoutStepId}
                                            exercise={workoutStep.exercise}
                                            key={workoutSet.workoutSetId} />  
                                    )}
                                    {supersetSteps?.map(supersetStep => {
                                        const exercise = getExerciseById(supersetStep.exercise);

                                        return (<>
                                            <div className="flex items-center mt-4">
                                                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                                                    <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                                                </div>
                                                <div className="ml-4 flex-1">
                                                    <p className="font-medium text-gray-900">{exercise?.name}</p>
                                                </div>
                                                <div className="flex-none flex items-center">

                                                </div>
                                            </div>
                                            {supersetStep.sets.map(superSet =>
                                                <WorkoutLoggerSet 
                                                    workoutSet={superSet}
                                                    workoutStepId={supersetStep.workoutStepId}
                                                    exercise={supersetStep.exercise}
                                                    key={superSet.workoutSetId} />  
                                            )}
                                        </>);
                                    })}
                                    {supersetSteps && supersetSteps?.length > 0 &&
                                        <div className="flex justify-center items-center absolute top-0 left-0 w-4 h-full overflow-hidden rounded-tl-lg rounded-bl-lg">
                                            <div className="w-full h-full flex items-center justify-center bg-blue-400">
                                                <span className="uppercase text-white text-sm font-medium transform -rotate-90">Superset</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <div className="fixed bottom-0 h-16 bg-white w-full md:w-full-minus-sidebar flex items-center justify-between shadow-soft border-t border-gray-200">
                        <button className={clsx("flex items-center justify-center h-full w-16 bg-blue-400 text-white transition-all hover:bg-blue-500", activeStep === 0 && "opacity-50 cursor-not-allowed")} onClick={() => navigateWorkout(WorkoutNav.PREV)}>
                            <FiChevronLeft />
                        </button>
                        <div className="h-full flex items-center font-medium text-lg text-gray-500">{activeStep + 1}/{programDay.workoutSteps.filter(workoutStep => workoutStep.parentStepId === undefined).length}</div>
                        {!isLastStep &&
                            <button className="flex items-center justify-center h-full w-16 bg-blue-400 text-white transition-all hover:bg-blue-500" onClick={() => navigateWorkout(WorkoutNav.NEXT)}>
                                <FiChevronRight />
                            </button>
                        }
                        {isLastStep &&
                            <button className="flex items-center justify-center h-full px-6 font-medium bg-green-400 text-white transition-all hover:bg-green-500" onClick={() => saveProgramLog(programDay.programId)}>Complete workout</button>
                        }
                    </div>
                </div>
            }
        </AppPageLayout>
    );
};