import { HTMLProps } from "react";
import { RadioGroup } from '@headlessui/react';


interface RadioGroupOptions {
    label: string;
    description?: string;
    value: string;
}

interface SmartRadioGroupProps {
  value: any;
  onChange: (val: any) => void;
  options: RadioGroupOptions[];
  label?: string;
  error?: string;
}

export function SmartRadioGroup({
  value,
  onChange,
  label,
  options,
  error,
  ...props
}: Partial<SmartRadioGroupProps & HTMLProps<HTMLDivElement>>): JSX.Element {
    if(!value || !onChange) return (<></>);

    return (
        <>
            <div className="w-full mb-8">
                <RadioGroup value={value} onChange={(val) => onChange(val)}>
                    <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
                    <div className="flex space-x-2">
                        {options && options.map(option =>
                            <RadioGroup.Option
                            key={option.value}
                            value={option.value}
                            className={({ active, checked }) =>
                            `${
                                active
                                ? 'ring-2 ring-black'
                                : ''
                            }
                            ${
                                checked ? 'bg-blue-400 bg-opacity-75 text-white' : 'bg-white'
                            }
                                relative rounded-md shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                            }
                        >
                            {({ active, checked }) => (
                            <>
                                <div className="flex items-center justify-between w-full">
                                <div className="flex items-center">
                                    <div className="text-sm">
                                    <RadioGroup.Label
                                        as="p"
                                        className={`font-medium  ${
                                        checked ? 'text-white' : 'text-gray-900'
                                        }`}
                                    >
                                        {option.label}
                                    </RadioGroup.Label>
                                    {option.description &&
                                        <RadioGroup.Description
                                            as="span"
                                            className={`inline pr-2 ${
                                            checked ? 'text-sky-100' : 'text-gray-500'
                                            }`}
                                        >
                                            <span>
                                            {option.description}
                                            </span>
                                        </RadioGroup.Description>
                                    }
                                    </div>
                                </div>
                                </div>
                            </>
                            )}
                        </RadioGroup.Option>
                        )}
                    </div>
                </RadioGroup>
            </div>
        </>
        // <div className="w-full">
        //     {label &&
        //         <label htmlFor={id} className="block text-sm font-medium text-black mb-1">{label}</label>
        //     }
        //     {register &&
        //         <textarea id={id} className={clsx(
        //         "h-44 w-full border border-gray-200 p-2 rounded-md",
        //           error && "border-2 border-red-500 outline-none"
        //         )} {...register} {...props}></textarea>
        //     }
        //     {error && <InputError error={error} />}
        // </div>
    );
}