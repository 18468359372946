import clsx from "clsx";
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { AppPageLayout } from "../../pages/PageLayouts";
import { BsCalendarEvent } from "react-icons/bs";
import { MdSpaceDashboard  } from "react-icons/md";
import Logo from '../../assets/svg/logo-full.svg';

export const PlaygroundWorkoutPage: React.FC = () => {
    const [selected, setSelected] = useState(-1);
    const [modal, setModal] = useState(false);

    return (
        <div className="relative w-full min-h-screen bg-gray-600 bg-opacity-5">
            <header className="w-full h-24 bg-white border-b border-gray-100 flex items-center px-8 shadow-soft">
                <img src={Logo} className="max-h-8" />
            </header>
            <main className="w-full border-t border-white pl-36 pt-8 pr-8">
                <aside style={{height: 680}} className="bg-white rounded-2xl fixed left-8 top-32 w-20 py-6 shadow-soft flex flex-col items-center">
                    <a href="#0" className="flex items-center justify-center w-12 h-12 rounded-md bg-blue-400 shadow-xl mb-4 group">
                        <MdSpaceDashboard className="text-white w-8 h-8" />
                    </a>
                    <a href="#0" className="w-12 h-12 rounded-md bg-gray-200 mb-4"></a>
                    <a href="#0" className="w-12 h-12 rounded-md bg-gray-200 mb-4"></a>
                    <a href="#0" className="w-12 h-12 rounded-md bg-gray-200 mb-4"></a>
                </aside>
                <div className="grid grid-cols-3 gap-8 max-w-7xl mx-auto">
                    <div className="col-span-2"></div>
                    <div className="bg-white bg-opacity-70 rounded-2xl w-full shadow-soft overflow-hidden">
                        <div className="flex flex-col items-center justify-center bg-white border-b border-gray-200 py-8">
                            <div className="w-32 h-32 bg-gray-200 rounded-full mb-8"></div>
                            <div className="font-medium text-lg">Scott Cunningham</div>
                        </div>
                        <div className="border-t border-white p-8">
                            <h2 className="font-medium text-2xl mb-8">Details</h2>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Date of birth</div>
                                <div className="font-medium">25/08/1993</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Gender</div>
                                <div className="font-medium">Male</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Email address</div>
                                <div className="font-medium">s.cunningham93@gmail.com</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Telephone</div>
                                <div className="font-medium">07852399502</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="grid grid-cols-2 bg-white bg-opacity-70 rounded-2xl w-full shadow-soft col-span-2 overflow-hidden">
                        <div className="flex flex-col items-center justify-center bg-white border-r border-gray-200">
                            <div className="w-32 h-32 bg-gray-200 rounded-full mb-8"></div>
                            <div className="font-medium text-lg">Scott Cunningham</div>
                        </div>
                        <div className="border-l border-white p-8">
                            <h2 className="font-medium text-2xl mb-8">Details</h2>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Date of birth</div>
                                <div className="font-medium">25/08/1993</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Gender</div>
                                <div className="font-medium">Male</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Email address</div>
                                <div className="font-medium">s.cunningham93@gmail.com</div>
                            </div>
                            <div className="mb-4">
                                <div className="font-medium text-sm opacity-30">Telephone</div>
                                <div className="font-medium">07852399502</div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white bg-opacity-40 rounded-2xl grid grid-cols-2 grid-rows-2 gap-6 p-6">
                        <div className="rounded-2xl bg-white shadow-soft transition-all hover:bg-blue-400 flex flex-col items-center justify-center group">
                            <BsCalendarEvent className="w-8 h-8 text-gray-400 transition-all group-hover:text-white" />
                            <div className="font-medium text-lg text-gray-400 mt-4 transition-all group-hover:text-white">Program</div>
                        </div>
                        <div className="rounded-2xl bg-white shadow-soft transition-all hover:bg-blue-400 flex flex-col items-center justify-center group">
                            <BsCalendarEvent className="w-8 h-8 text-gray-400 transition-all group-hover:text-white" />
                            <div className="font-medium text-lg text-gray-400 mt-4 transition-all group-hover:text-white">Program</div>
                        </div>
                        <div className="rounded-2xl bg-white shadow-soft transition-all hover:bg-blue-400 flex flex-col items-center justify-center group">
                            <BsCalendarEvent className="w-8 h-8 text-gray-400 transition-all group-hover:text-white" />
                            <div className="font-medium text-lg text-gray-400 mt-4 transition-all group-hover:text-white">Program</div>
                        </div>
                        <div className="rounded-2xl bg-white shadow-soft transition-all hover:bg-blue-400 flex flex-col items-center justify-center group">
                            <BsCalendarEvent className="w-8 h-8 text-gray-400 transition-all group-hover:text-white" />
                            <div className="font-medium text-lg text-gray-400 mt-4 transition-all group-hover:text-white">Program</div>
                        </div>
                    </div> */}
                </div>
            </main>
        </div>
        // <AppPageLayout>
        //     <div className="rounded-md overflow-hidden bg-white shadow-soft">
        //         <div className="w-full p-4 border-b">
        //             <div className="font-medium text-lg">Week 1</div>
        //         </div>
        //         <div className="w-full flex flex-col md:flex-row">
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 1</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 2</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 3</div>
        //                 <div className="text-sm opacity-70">Rest day</div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 4</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 5</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 6</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 7</div>
        //                 <div className="text-sm opacity-70">Legs</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="rounded-md overflow-hidden bg-white shadow-soft mt-8">
        //         <div className="w-full p-4 border-b">
        //             <div className="font-medium text-lg">Week 2</div>
        //         </div>
        //         <div className="w-full flex flex-col md:flex-row">
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 1</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 2</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 3</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 4</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 5</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 6</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //             <div className={clsx(
        //                 "relative group w-full h-20 md:w-1/7 md:h-80 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-auto md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r p-4 transition-all",
        //                 selected === 0 && "bg-gray-100 text-white w-80 flex-none")} onClick={() => setModal(true)}>
        //                 <div className="font-medium text-sm">Day 7</div>
        //                 <div className="w-72 mt-2">
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Barbell Squats</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Front Squats with Squirrel</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                     <div className="mb-2 text-sm">
        //                         <div className="font-medium">Dumbbell Lunges</div>
        //                         <div className="-mt-1">3 sets</div>
        //                     </div>
        //                 </div>
        //                 <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>
        //             </div>
        //         </div>
        //     </div>
        //     <button className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 mt-8 w-full h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100">
        //             <FiPlusSquare className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
        //             <span className="sr-only">Add Week</span>
        //     </button>
        //     <Transition appear show={modal} as={Fragment}>
        //         <Dialog
        //         as="div"
        //         className="fixed inset-0 z-20 overflow-y-auto bg-gray-800 bg-opacity-80"
        //         onClose={() => setModal(false)}
        //         >
        //         <div className="min-h-screen px-4 text-center">
        //             <Transition.Child
        //             as={Fragment}
        //             enter="ease-out duration-300"
        //             enterFrom="opacity-0"
        //             enterTo="opacity-100"
        //             leave="ease-in duration-200"
        //             leaveFrom="opacity-100"
        //             leaveTo="opacity-0"
        //             >
        //             <Dialog.Overlay className="fixed inset-0" />
        //             </Transition.Child>

        //             {/* This element is to trick the browser into centering the modal contents. */}
        //             <span
        //             className="inline-block h-screen align-middle"
        //             aria-hidden="true"
        //             >
        //             &#8203;
        //             </span>
        //             <Transition.Child
        //             as={Fragment}
        //             enter="ease-out duration-300"
        //             enterFrom="opacity-0 scale-95"
        //             enterTo="opacity-100 scale-100"
        //             leave="ease-in duration-200"
        //             leaveFrom="opacity-100 scale-100"
        //             leaveTo="opacity-0 scale-95"
        //             >
        //             <div className="inline-block w-full max-w-6xl my-8 text-left align-middle transition-all transform">
        //                 <div className="grid grid-cols-3 gap-4">
        //                     <div className="bg-white shadow-xl rounded-md p-6 col-span-2">
        //                     <Dialog.Title
        //                         as="h3"
        //                         className="text-xl font-medium leading-6 text-gray-900"
        //                     >
        //                         Day 1
        //                     </Dialog.Title>
        //                     <div className="mt-2 border-b pb-4">
        //                         <p className="text-sm text-gray-500">
        //                             Leg Day
        //                         </p>
        //                     </div>

        //                     <div className="pt-4">
        //                         <div className="p-2 transition duration-150 ease-in-out rounded-md group">
        //                             <div className="flex items-center">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">1.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">2.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">3.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     <div className="pt-4">
        //                         <div className="p-2 transition duration-150 ease-in-out rounded-md group">
        //                             <div className="flex items-center">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="font-medium text-gray-900">Front Squats</p>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">1.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">2.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">3.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     <div className="pt-4">
        //                         <div className="p-2 transition duration-150 ease-in-out rounded-md group">
        //                             <div className="flex items-center">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">1.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">2.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                             <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
        //                                 <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">3.</div>
        //                                 <div className="flex items-center">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                                 </div>
        //                                 <div className="flex items-center ml-4">
        //                                     <input className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} />
        //                                     <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     </div>
        //                     <div className="bg-white shadow-xl rounded-md p-6 col-span-1 sticky top-6 h-screen-padded">
        //                         <div className="text-xl font-medium leading-6 text-gray-900">
        //                             Exercise library
        //                         </div>
        //                         <div className="relative flex-none w-full mt-4 border-gray-300 md:w-auto">
        //                             <input type="text" placeholder="Search exercises..." className="h-12 w-full border border-gray-200 pl-4 pr-12 rounded-md" />
        //                             <div className="absolute right-0 top-0 w-12 h-full flex items-center justify-center">
        //                                 <FiSearch className="w-4 h-4" />
        //                             </div>
        //                         </div>
        //                         <div className="mt-6">
        //                             <a href="#0" className="flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="text-sm font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </a>
        //                             <a href="#0" className="flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="text-sm font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </a>
        //                             <a href="#0" className="flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="text-sm font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </a>
        //                             <a href="#0" className="flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="text-sm font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </a>
        //                             <a href="#0" className="flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50">
        //                                 <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
        //                                     <FiPackage className="w-6 h-6 text-blue-500" />
        //                                 </div>
        //                                 <div className="ml-4">
        //                                     <p className="text-sm font-medium text-gray-900">Barbell Squats</p>
        //                                 </div>
        //                             </a>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             </Transition.Child>
        //         </div>
        //         </Dialog>
        //     </Transition>
        // </AppPageLayout>
    );
};