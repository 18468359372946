import clsx from "clsx";
import { Loader } from ".";

type BackgroundColours = "blue" | "transparent"; 

interface FullScreenLoaderProps {
    backgroundColor?: BackgroundColours;
}

export const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({
    backgroundColor
}) => {
    return (
        <div className={clsx(
            "fix top-0 left-0 w-screen h-screen flex items-center justify-center",
            !backgroundColor || backgroundColor === "blue" && "bg-blue-500",
            backgroundColor === "transparent" && "bg-transparent"    
        )}>
            <Loader />
        </div>
    );
};