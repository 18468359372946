import { IApiUser } from "./User";

export enum ActivityType {
    WORKOUT_LOG = 1
}

export interface IApiActivity {
  _id: string;
  user: IApiUser;
  vendor: string;
  type: ActivityType;
  activityId: string;
  createdAt: string;
  updatedAt: string;
}