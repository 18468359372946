import { HeaderAvatarDropdown } from ".";
import { FiMenu } from 'react-icons/fi';
import { useUI } from "../../ui/UIContext";
import { IconButton } from "../Buttons";
import LogoFull from '../../assets/svg/logo-full.svg';
import { Link } from "react-router-dom";

export const Header: React.FC = () => {
    const { navIsOpen, setNavIsOpen } = useUI();
    return (
        <header className="fixed top-0 left-0 h-20 w-full border-b bg-white border-gray-200 flex items-center pl-4 pr-4 z-20">
            <Link to='/' className="relative flex justify-center items-center h-6">
                <img src={LogoFull} alt="PT Sphere Logo" className="relative h-6 lg:ml-4 w-auto" />
            </Link>
            <div className="hidden md:flex ml-auto items-center">
                <HeaderAvatarDropdown />
            </div>
            <div className="flex ml-auto items-center md:hidden">
                <IconButton label='Open menu' onClick={() => setNavIsOpen(!navIsOpen)}>
                    <FiMenu className="h-6 w-6" />
                </IconButton>
            </div>
        </header>
    );
};