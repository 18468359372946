import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FiMoreVertical, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { AppPathsClient } from "../../routes/AppRoutes";
import { Avatar } from "../Avatar";

export const ProfileCard: React.FC = () => {
    const { user } = useAuth();

    const initials = `${user?.firstname[0]}${user?.lastname[0]}`; 

    return (
        <div className="relative bg-white h-72 rounded-md flex items-center justify-center p-4">
            <div className="flex flex-col items-center">
                <Avatar />
                <div className="mt-4 font-medium text-gray-500">{user?.firstname} {user?.lastname}</div>
            </div>
            <div className="absolute top-4 right-4 w-6 h-6 flex items-center justify-center">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                    <FiMoreVertical
                        className="w-5 h-5"
                        aria-hidden="true"
                        />
                    <span className="sr-only">Options</span>
                </Menu.Button>
                </div>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        <Menu.Item>
                            {({ active }) => (
                            <Link to={AppPathsClient.profile} className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <FiUser 
                                    className="w-5 h-5 mr-2"
                                    aria-hidden="true" 
                                />
                                <label className='flex items-center cursor-pointer w-full'>
                                    My Profile
                                </label>
                            </Link>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
                </Transition>
            </Menu>
            </div>
        </div>
    );
};