import { AppPageHeader } from "../../../components/AppPageHeader";
import { ProfileCard } from "../../../components/ProfileCard";
import { TestFormIncludingFile } from "../../../forms/TestFormIncludingFile";
import { AppPageLayout } from "../../PageLayouts";

export const ClientDashboardPage: React.FC = () => {    
    return (
        <AppPageLayout title='Dashboard'>
            <AppPageHeader title="Dashboard" />
            <div className="px-6 pt-2 pb-6">
                <div className="w-full grid gap-4 lg:grid-cols-3">
                    <ProfileCard />
                    {/* <TestFormIncludingFile /> */}
                </div>
            </div>
        </AppPageLayout>
    );
};