import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { updateUserDetails } from "../../api/user";
import { useAuth } from "../../auth/AuthContext";
import { Button } from "../../components/Buttons";
import { SmartInput } from "../../components/SmartForm";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { toastNotification } from "../../components/ToastNotification";
import { GENDER } from "../../models/User";

interface CompleteClientProfileFormInputs {
    day: number;
    month: number;
    year: number;
    phone: string;
    gender: number;
}

const schema = yup.object({
    day: yup.number().lessThan(32, 'Please enter a valid date').required('Date is required'),
    month: yup.number().lessThan(13, 'Please enter a valid month').required('Month is required'),
    year: yup.number().lessThan(DateTime.now().get('year'), 'Please enter a valid year in the past').required('Year is required'),
    phone: yup.string().matches(/^[0-9]*$/g, 'Please enter a valid phone number').required('Phone is required'),
    gender: yup.number().required('Gender is required')
});

const genderOptions = [
    {
        label: 'Male',
        value: '1',
        description: ''
    },
    {
        label: 'Female',
        value: '2',
        description: ''
    }
];

export const CompleteClientProfileForm: React.FC = () => {
    const { user, updateUser } = useAuth();
    const [gender, setGender] = useState<GENDER>(GENDER.MALE);

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm<CompleteClientProfileFormInputs>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (values: CompleteClientProfileFormInputs) => {
        const { day, month, year, phone, gender} = values;

        const dob = DateTime.now().set({ day, month, year }).toJSDate().toISOString();

        const result = await updateUserDetails(user?._id ?? '', { ...values, dob });

        if(!result.isError) {
            updateUser(result.content);
            toastNotification({ variant: 'success', title: 'Profile updated', message: `Your profile has been successfully updated` });
        }
    };

    const handleGenderChange = (val: GENDER) => {
        setGender(val);
        setValue('gender', val);
    };

    setValue('gender', gender);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label className="block text-sm font-medium text-black mb-1">Gender</label>
            <SmartRadioGroup options={genderOptions} value={gender.toString()} onChange={(val: any) => handleGenderChange(val as GENDER)} />
            <label className="block text-sm font-medium text-black mb-1">Date of birth (DD/MM/YYYY)</label>
            <div className="flex items-end mb-4">
                <div className="mr-2 w-16">
                    <SmartInput type='number' register={register('day')} autoComplete="false" placeholder="DD" error={errors.day?.message} />
                </div>
                <div className="mr-2 w-16">
                    <SmartInput type='number' register={register('month')} autoComplete="false" placeholder="MM" error={errors.month?.message} />
                </div>
                <div className="mr-2 w-24">
                    <SmartInput type='number' register={register('year')} autoComplete="false" placeholder="YYYY" error={errors.year?.message} />
                </div>
            </div>
            <div className="mb-4 w-full max-w-xs">
                <SmartInput register={register('phone')} autoComplete="false" placeholder="Phone number" label="Phone number" error={errors.phone?.message} />
            </div>
            <div className="w-full">
                <Button variant='primary' type='submit'>Complete profile</Button>
            </div>
        </form>
    );
};