import { FiPlusSquare, FiCalendar } from "react-icons/fi";
import { ProgramWeek } from ".";
import { WorkoutEditor } from "../WorkoutEditor";
import { useProgramEditor } from "./ProgramEditorContext/ProgramEditorContext";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import clsx from "clsx";
import { DateTime } from "luxon";
import { AppPageHeader } from "../AppPageHeader";
export const ProgramEditor: React.FC= () => {
    const { program, programWeeks, clientProgram, notFound, addWeek, handleDragAndDrop } = useProgramEditor();

    const client = clientProgram?.client;
    const initials = `${client?.firstname[0]}${client?.lastname[0]}`;
    const startDate = DateTime.fromJSDate(new Date(clientProgram?.startDate ?? '')).toFormat('d MMM yyyy')

    return (
        <>
        <DragDropContext onDragEnd={(result) => handleDragAndDrop(result)}>
            {notFound &&
                <h1 className="text-3xl font-medium">Ooops... Workout program not found!</h1>
            }
            <AppPageHeader title={program?.name ?? ''} primaryAction={
                <div className="ml-auto flex items-center">
                    <div className="inline-flex items-center bg-white rounded-md shadow-soft px-5 py-4">
                        <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
                            <div className="w-full h-full flex items-center justify-center text-gray-400 font-medium">
                                <span>{initials}</span>
                            </div>
                        </div>
                        <div className="font-medium ml-4">{client?.firstname} {client?.lastname}</div>
                    </div>
                    <div className="inline-flex items-center bg-blue-400 rounded-md shadow-soft px-5 py-4 ml-4">
                        <FiCalendar className="text-white w-8 h-8" />
                        <div className="font-medium ml-4 text-white">Start: {startDate}</div>
                    </div>
                </div>
            } />
            <div className="px-6 pt-4 pb-6">
                <Droppable droppableId='week-drop-zone' type='WEEK'>
                    {(provided, snapshot) => (
                        <div 
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={clsx("w-full")}>
                                {programWeeks && programWeeks.length > 0 && programWeeks.map(programWeek =>
                                    <ProgramWeek key={programWeek._id} programWeek={programWeek} />
                                )}
                                {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {!notFound &&
                    <button 
                        className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 mt-8 w-full h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100"
                        onClick={() => addWeek()}>
                        <FiPlusSquare className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
                        <span className="sr-only">Add Week</span>
                    </button>
                }
            </div>
            <WorkoutEditor />
        </DragDropContext>
        </>
    );
};