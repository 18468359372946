import { useQuery } from "react-query";
import { ScheduleContent, ScheduleEmptyState } from ".";
import { IApiGetClientProgramSchedule, IApiProgramDay } from "../../../api/models/Program";
import { getClientProgramSchedule } from "../../../api/program";
import { RelativeLoader } from "../../../components/Loader";
import { AppPageLayout } from "../../PageLayouts";
import { ScheduleSkeleton } from "./ScheduleSkeleton";

const populateRestDays = (clientProgramSchedule: IApiGetClientProgramSchedule): IApiGetClientProgramSchedule => {
    const dayNos = [0, 1, 2, 3, 4, 5, 6];

    const createRestDayObj = (dayNo: number, programWeekId: string): IApiProgramDay => {
        return {
            programId: clientProgramSchedule.programs[0]._id,
            name: 'Rest day',
            dayNo,
            workoutSteps: [],
            programWeekId
        };
    }

    for(const programWeek of clientProgramSchedule.programWeeks) {
        for(const dayNo of dayNos) {
            const programDayExists = Boolean(clientProgramSchedule.programDays.filter(programDay => programDay.programWeekId === programWeek._id).find(programDay => programDay.dayNo === dayNo));

            if(!programDayExists) {
                const restDay = createRestDayObj(dayNo, programWeek._id);
                clientProgramSchedule.programDays.push(restDay);
            }
        }
    }
    clientProgramSchedule.programDays.sort((a, b) => (a.dayNo > b.dayNo) ? 1 : -1);

    return clientProgramSchedule;
};

export const SchedulePage: React.FC = () => {
    const { isLoading, error, data: scheduleData, refetch } = useQuery(`schedule`, async () => { 
        const result = await getClientProgramSchedule();
        if(!result.isError) {
            return populateRestDays(result.content);
        }   
    });
    
    return (
        <AppPageLayout title='Schedule'>
            {isLoading &&
                <ScheduleSkeleton />
            }
            {!isLoading && scheduleData?.clientPrograms && scheduleData.clientPrograms.length > 0 &&
                <ScheduleContent scheduleData={scheduleData} />
            }
            {!isLoading && scheduleData?.clientPrograms && scheduleData.clientPrograms.length === 0 &&
                <ScheduleEmptyState />
            }
        </AppPageLayout>
    );
};