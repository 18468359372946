import { Menu, Transition } from '@headlessui/react';
import { useProgramEditor } from "../../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { Fragment, useEffect, useRef, useState } from 'react';
import { FiMoreVertical, FiPlus, FiTrash2 } from 'react-icons/fi';
import { MEASUREMENT_TYPE, WorkoutSet, WorkoutStep } from '../../../api/models/Program';
import { Category, EXERCISE_TYPE, IApiExercise } from '../../../api/models/Exercise';

interface Props {
    workoutStepId: string;
    workoutSet: WorkoutSet;
    exercise: IApiExercise | null;
}

export const WorkoutEditorSetMenu: React.FC<Props> = ({
    workoutStepId,
    workoutSet,
    exercise
}) => {
    const { removeSet, editProgramSet } = useProgramEditor();

    const isCardioExercise = exercise?.category === Category.cardio;

    return (
        <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                <FiMoreVertical
                    className="w-5 h-5"
                    aria-hidden="true"
                    />
                <span className="sr-only">Options</span>
            </Menu.Button>
            </div>
            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                    {!isCardioExercise &&
                        <>
                        <Menu.Item>
                            {({ active }) => (
                            <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <label className='flex items-center cursor-pointer w-full'>
                                    <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.WEIGHT} checked={workoutSet.measurementType === MEASUREMENT_TYPE.WEIGHT} className='ml-1 mr-3' />
                                    Weight (KG)
                                </label>
                            </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <label className='flex items-center cursor-pointer w-full'>
                                    <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.RPE} checked={workoutSet.measurementType === MEASUREMENT_TYPE.RPE} className='ml-1 mr-3' />
                                    RPE
                                </label>
                            </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <label className='flex items-center cursor-pointer w-full'>
                                    <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.PRECENTAGE} checked={workoutSet.measurementType === MEASUREMENT_TYPE.PRECENTAGE} className='ml-1 mr-3' />
                                    %1RM
                                </label>
                            </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <label className='flex items-center cursor-pointer w-full'>
                                    <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.TIME} checked={workoutSet.measurementType === MEASUREMENT_TYPE.TIME} className='ml-1 mr-3' />
                                    Time
                                </label>
                            </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                <label className='flex items-center cursor-pointer w-full'>
                                    <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.INTERVAL} checked={workoutSet.measurementType === MEASUREMENT_TYPE.INTERVAL} className='ml-1 mr-3' />
                                    Interval
                                </label>
                            </div>
                            )}
                        </Menu.Item>
                        </>
                    }
                    {isCardioExercise &&
                        <>
                            <Menu.Item>
                                {({ active }) => (
                                <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                    <label className='flex items-center cursor-pointer w-full'>
                                        <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.CARDIO_DISTANCE} checked={workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_DISTANCE} className='ml-1 mr-3' />
                                        Distance
                                    </label>
                                </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                    <label className='flex items-center cursor-pointer w-full'>
                                        <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.CARDIO_TIME} checked={workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_TIME} className='ml-1 mr-3' />
                                        Time
                                    </label>
                                </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <div className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer">
                                    <label className='flex items-center cursor-pointer w-full'>
                                        <input type="radio" name="measurementType" onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'measurementType', parseInt(e.currentTarget.value))} value={MEASUREMENT_TYPE.CARDIO_INTERVAL} checked={workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_INTERVAL} className='ml-1 mr-3' />
                                        Interval
                                    </label>
                                </div>
                                )}
                            </Menu.Item>
                        </>
                    }
                </div>
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-red-200 text-red-600' : 'text-red-600'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => removeSet(workoutStepId, workoutSet.workoutSetId)}
                        >
                            <FiTrash2 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Remove set
                        </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
        </div>
    );
};