import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Button } from "../../components/Buttons";
import { SmartInput } from "../../components/SmartForm";

export interface DetailsFormInputs {
    firstname: string,
    lastname: string,
    email: string,
    phone: string
}

interface DetailsFormProps {
    onSubmit: (values: DetailsFormInputs) => void
}

const schema = yup.object({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    email: yup.string().email('Please enter a valid email address').required('Email address is required'),
    phone: yup.string().required('Telephone number is required'),
}).required();

export const DetailsForm: React.FC<DetailsFormProps> = ({
    onSubmit
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<DetailsFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="grid sm:grid-cols-2 gap-4 auto-rows-min flex-1">
                <SmartInput register={register('firstname')} placeholder='First name' label='First name' error={errors.firstname?.message} />
                <SmartInput register={register('lastname')} placeholder='Last name' label='Last name' error={errors.lastname?.message} />
                <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
                <SmartInput register={register('phone')} type='number' placeholder='Phone number' label='Phone number' error={errors.phone?.message} />
            </div>
            <div className='mt-auto grid grid-cols-2 gap-4'>
                <div></div>
                <Button type='submit'>Next</Button>
            </div>
        </form>
    );
};