import clsx from "clsx";

export interface AppBadgeProps {
    variant?: 'success' | 'warning' | 'danger';
}

export const AppBadge: React.FC<AppBadgeProps> = ({
    variant,
    children
}) => {
    return (
        <div 
            className={clsx(
                "font-medium px-4 py-2 rounded-md",
                !variant && "bg-blue-100 text-blue-600",
                variant === 'success' && "bg-green-100 text-green-600",
                variant === 'warning' && "bg-yellow-100 text-yellow-600",
                variant === 'danger' && "bg-red-100 text-red-600",
        )}>
            {children}
        </div>
    );
};