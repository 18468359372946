import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { AppPageLayout } from "../../PageLayouts";
import { FiCheck } from 'react-icons/fi';
import { Button } from '../../../components/Buttons';
import { CreateProgramForm } from '../../../forms/CreateProgramForm';

export const CreateWorkoutPlanPage: React.FC = () => {
    const [selected, setSelected] = useState(1);
    return (
        <AppPageLayout title='Create Workout Program'>
            <div className="px-6 pt-8 pb-6 max-w-7xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-4xl font-medium mb-1">Create workout program</h1>
                    <p className="font-medium opacity-70">Build a new workout program in just a few easy steps!</p>
                    <hr className="mt-4" />
                </div>
                <CreateProgramForm />
            </div>
        </AppPageLayout>
    );
};