import clsx from "clsx";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { AdminClientDetailPaths } from "..";
import { getLogsByStartAndEndDateAndClientId } from "../../../../api/programLog";
import { useSticky } from "../../../../hooks/useSticky";
import { ClientDetailLogbookSkeleton } from "./ClientDetailLogbookSkeleton";
import { ClientLogbookEmptyState } from "./ClientLogbookEmptyState";
import { ClientLogbookItem } from "./ClientLogbookItem";

interface Params {
    id: string;
    month: string;
    year: string;
}

export const ClientDetailLogbook: React.FC = () => {
    const [stickyLogbookHeader, sticky] = useSticky<HTMLDivElement>();
    const { id, month, year } = useParams<Params>();
    const history = useHistory();

    const start = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1}).startOf('month').toJSDate().toISOString();
    const end = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1}).endOf('month').toJSDate().toISOString();

    const { isLoading, error, data: logbookItems, refetch } = useQuery(`logbook/client/${start}/${end}/${id}`, async () => { 
        const result = await getLogsByStartAndEndDateAndClientId(start, end, id);
        if(!result.isError) {
            return result.content;
        }   
    });

    const launchYear = 2021;
    const yearsPastLaunch = parseInt(DateTime.now().toFormat("yyyy")) - (launchYear - 1);

    const handleMonthChange = (selectedMonth: string) => {
        history.push(AdminClientDetailPaths.logbook(id, selectedMonth, year));
    };

    const handleYearChange = (selectedYear: string) => {
        history.push(AdminClientDetailPaths.logbook(id, month, selectedYear));
    };

    return (
        <>
            <div className={clsx("flex items-center mb-2 py-2 sticky top-20 transition-all", sticky && "bg-gray-100")} ref={stickyLogbookHeader}>
                <h2 className="text-2xl font-medium">Logbook</h2>
                <div className="ml-auto flex items-center">
                    <select value={month} onChange={(e) => handleMonthChange(e.currentTarget.value)} className="bg-white rounded-md border border-gray-200 h-12 px-2 mr-2 bg-transparent border-none">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <select value={year} onChange={(e) => handleYearChange(e.currentTarget.value)} className="bg-white rounded-md border border-gray-200 h-12 px-2 bg-transparent border-none">
                        {Array(yearsPastLaunch).fill(null).map((_, i) =>
                            <option value={launchYear + i}>{launchYear + i}</option>
                        )}
                    </select>
                </div>
            </div>
            <div>
            {isLoading && 
                <ClientDetailLogbookSkeleton />
            }
            {!isLoading && logbookItems && logbookItems.length > 0 && logbookItems.map(item => 
                <ClientLogbookItem logbookItem={item} key={item._id} />
            )}
            {!isLoading && logbookItems?.length === 0 &&
                <ClientLogbookEmptyState />
            }
            </div>
        </>
    );
};