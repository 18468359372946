import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { vendorSignUp } from '../../../api/authentication';
import LogoFullWhite from '../../../assets/svg/logo-full-white.svg';
import { SteppedContent } from '../../../components/SteppedContent';
import { BusinessForm, DetailsForm, PasswordForm, SubScriptionForm } from '../../../forms/SignUpForm';
import { BusinessFormInputs } from '../../../forms/SignUpForm/BusinessForm';
import { DetailsFormInputs } from '../../../forms/SignUpForm/DetailsForm';
import { PasswordFormInputs } from '../../../forms/SignUpForm/PasswordForm';
import { SubscriptionFormInputs } from '../../../forms/SignUpForm/SubscriptionForm';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { AppPathsAdmin } from '../../../routes/AppRoutes';

export type SignUpFormInputs = BusinessFormInputs | DetailsFormInputs | PasswordFormInputs | SubscriptionFormInputs;
type SignUpFormValues = BusinessFormInputs & DetailsFormInputs & PasswordFormInputs & SubscriptionFormInputs;

const defaultFormValues: SignUpFormValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    businessName: '',
    password: '',
    confirmPassword: '',
    paymentMethodId: ''
};

export const SignUpPage: React.FC = () => {
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<SignUpFormValues>(defaultFormValues);
    useDocumentTitle('Sign Up');

    const signUpRequest = useMutation(`${formData.businessName}-signup`, async (signUpDto: SignUpFormValues) => {
        const result = await vendorSignUp(signUpDto);
        if(!result.isError) {
            history.push(AppPathsAdmin.dashboard);
        } 
    });

    const submitSignUp = async (dto: SignUpFormValues) => {
        signUpRequest.mutate(dto);
    };

    const handleFormStepSubmit = (values: SignUpFormInputs) => {
        const signUpDto = {
            ...formData,
            ...values
        };

        setFormData(signUpDto);
        
        if(step < 4) {
            setStep(step + 1);
        }

        if(step === 4) {
            submitSignUp(signUpDto);
        } 
    };

    const previousStep = () => setStep(step - 1);

    return (
        <div className="h-screen w-full bg-gray-200 lg:flex">
            <div className="relative flex-1 h-40 lg:h-full bg-gray-50">
                <img src="https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80" alt="desc" className="hidden lg:block h-full w-full object-cover object-center" />
                <div className="absolute top-0 left-0 w-full h-full p-8 bg-gray-900 bg-opacity-40 bg-blend-multiply flex flex-col items-center justify-center">
                    <img src={LogoFullWhite} alt="PT Sphere Logo" className="h-10" />
                </div>
            </div>
            <div className="flex flex-col flex-1 bg-white h-full">
                <div className="py-4 px-8 border-b border-gray-200">
                    <h1 className="text-3xl mb-2 font-medium">Sign up</h1>
                    <p>Get signed up in a few easy steps!</p>
                </div>
                <SteppedContent label='1. Details' isActive={step === 1}>
                    <DetailsForm onSubmit={handleFormStepSubmit} />
                </SteppedContent>
                <SteppedContent label='2. Business' isActive={step === 2}>
                    <BusinessForm onSubmit={handleFormStepSubmit} onPrev={previousStep} />
                </SteppedContent>
                <SteppedContent label='3. Password' isActive={step === 3}>
                    <PasswordForm onSubmit={handleFormStepSubmit} onPrev={previousStep} />
                </SteppedContent>
                <SteppedContent label='4. Subscription' isActive={step === 4}>
                    <SubScriptionForm onSubmit={handleFormStepSubmit} onPrev={previousStep} />
                </SteppedContent>
            </div>
        </div>
    );
};

{/* <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-2 gap-4">
                            <SmartInput 
                                type='text' 
                                placeholder='First name...' 
                                label='First name'
                                error={errors.firstname?.message} 
                                register={register('firstname')} />
                            <SmartSelect 
                                register={register('age')} 
                                error={errors.age?.message}
                                label='Select label'
                                options={[
                                {
                                    value: 1,
                                    label: 'One'
                                },
                                {
                                    value: 2,
                                    label: 'Two'
                                }
                            ]} />
                            <Button type='submit'>Submit</Button>
                        </div>
                    </form> */}