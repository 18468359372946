import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { ClientDetailPage } from '../pages/AdminPages/ClientDetail';
import { ClientsPage } from '../pages/AdminPages/Clients';
import { HomePage } from '../pages/AdminPages/Home';
import { SignInPage } from '../pages/PublicPages/SignIn';
import { SignUpPage } from '../pages/PublicPages/SignUp';
import { CreateWorkoutPlanPage } from '../pages/AdminPages/CreateWorkoutPlan';
import { PlaygroundWorkoutPage } from '../playground/workout/PlaygroundWorkout';
import RouteChangeScrollToTop from './RouteChangeScrollToTop';
import { EditWorkoutPlanPage } from '../pages/AdminPages/EditWorkoutPlan';
import { ClientConfirmationPage } from '../pages/PublicPages/ClientConfirmation';
import { SchedulePage } from '../pages/ClientPages/Schedule';
import { WorkoutPage } from '../pages/ClientPages/Workout';
import { ClientDashboardPage } from '../pages/ClientPages/Dashboard';
import { WorkoutCompletePage } from '../pages/ClientPages/WorkoutComplete';
import { LogbookPage } from '../pages/ClientPages/Logbook';
import { LogbookItemPage } from '../pages/ClientPages/LogbookItem';
import { ClientLogbookItemPage } from '../pages/AdminPages/ClientLogbookItem';
import PageViewAnalytics from './PageViewAnalytics';
import { ForgottenPasswordPage } from '../pages/PublicPages/ForgottenPassword';
import { ProfilePage } from '../pages/ClientPages/Profile';
import { ProfilePage as AdminProfilePage } from '../pages/AdminPages/Profile';
import { TestPage } from '../pages/PublicPages/Test';

export enum ROLES {
    CLIENT,
    COACH,
    SUPER
}

export const AppPathsPublic = {
    home: '/',
    signUp: '/sign-up',
    forgottenPassword: '/forgotten-password',
    clientConfirmation: (confirmationCode: string) => `/confirm/${confirmationCode}`
}

export const AppPathsAdmin = {
    clients: '/clients',
    clientDetail: (id: string) => `/clients/${id}`,
    clientLogbookItem: (id: string, log: string) => `/logbook/item/${id}/${log}`,
    dashboard: '/',
    workoutPlanCreate: '/workout/plan/create',
    workoutPlanEdit: (id: string) => `/workout/plan/edit/${id}`,
    profile: '/profile',
    playground: '/playground',
};

export const AppPathsClient = {
    dashboard: '/',
    schedule: '/schedule',
    profile: '/profile',
    workout: (id: string, step: string) => `/workout/log/${id}/${step}`,
    workoutComplete: (id: string) => `/workout/complete/${id}`,
    logbook: (month: string, year: string) => `/logbook/items/${month}/${year}`,
    logbookItem: (id: string) => `/logbook/item/${id}`
}

export const AppRouter: React.FC = () => {
    const { user } = useAuth();

    const isAdmin = (user?.role === ROLES.COACH || user?.role === ROLES.SUPER);
    const isClient = user?.role === ROLES.CLIENT;

    return (
        <>
            <RouteChangeScrollToTop />
            <PageViewAnalytics />
            <Switch>
                {!user &&
                    <>
                    <Route path={AppPathsPublic.home} exact>
                        <SignInPage />
                    </Route>
                    <Route path={AppPathsPublic.signUp} exact>
                        <SignUpPage />
                    </Route>
                    <Route path={AppPathsPublic.forgottenPassword} exact>
                        <ForgottenPasswordPage />
                    </Route>
                    <Route path={AppPathsPublic.clientConfirmation(':confirmationCode')} exact>
                        <ClientConfirmationPage />
                    </Route>
                    <Route path="/test" exact>
                        <TestPage />
                    </Route>
                    </>
                    }
                {isAdmin &&
                    <>
                    <Route path={AppPathsAdmin.dashboard} exact>
                        <HomePage />
                    </Route>
                    <Route path={AppPathsAdmin.profile} exact>
                        <AdminProfilePage />
                    </Route>
                    <Route path={AppPathsAdmin.clients} exact>
                        <ClientsPage />
                    </Route>
                    <Route path={AppPathsAdmin.clientDetail(':id')}>
                        <ClientDetailPage />
                    </Route>
                    <Route path={AppPathsAdmin.clientLogbookItem(':id', ':log')}>
                        <ClientLogbookItemPage />
                    </Route>
                    <Route path={AppPathsAdmin.workoutPlanCreate}>
                        <CreateWorkoutPlanPage />
                    </Route>
                    <Route path={AppPathsAdmin.workoutPlanEdit(':id')} exact>
                        <EditWorkoutPlanPage />
                    </Route>
                    <Route path={AppPathsAdmin.playground} exact>
                        <PlaygroundWorkoutPage />
                    </Route>
                    </>
                }
                {isClient && 
                    <>
                        <Route path={AppPathsClient.dashboard} exact>
                            <ClientDashboardPage />
                        </Route>
                        <Route path={AppPathsClient.profile} exact>
                            <ProfilePage />
                        </Route>
                        <Route path={AppPathsClient.schedule} exact>
                            <SchedulePage />
                        </Route>
                        <Route path={AppPathsClient.workout(':id', ':step')} exact>
                            <WorkoutPage />
                        </Route>
                        <Route path={AppPathsClient.workoutComplete(':id')} exact>
                            <WorkoutCompletePage />
                        </Route>
                        <Route path={AppPathsClient.logbook(':month', ':year')} exact>
                            <LogbookPage />
                        </Route>
                        <Route path={AppPathsClient.logbookItem(':id')} exact>
                            <LogbookItemPage />
                        </Route>
                    </>
                }
                <Route render={() => <Redirect to='/' />} />
            </Switch>
        </>
    );
};