import { string } from "yup"
import { IApiUser } from "./User";

export enum ProgramType {
    WEEKLY = 'weekly',
    CYCLE = 'cycle'
}

export enum MEASUREMENT_UNIT {
    IMPERIAL = 1,
    METRIC = 2,
    BODYWEIGHT = 3
}

export enum MEASUREMENT_TYPE {
    WEIGHT = 1,
    RPE = 2,
    PRECENTAGE = 3,
    TIME = 4,
    INTERVAL = 5,
    CARDIO_DISTANCE = 6,
    CARDIO_INTERVAL = 7,
    CARDIO_TIME = 8
}

export interface WorkoutSet {
    workoutSetId: string;
    order: number;
    reps: number;
    measurementType: MEASUREMENT_TYPE;
    weight?: {
        value: number;
        units: MEASUREMENT_UNIT;
    };
    time?: number;
    interval?: {
        intOn: number;
        intOff: number;
        rounds: number;
    };
    rpe?: number;
    percentage?: number;
    speed?: number;
    distance?: number;
}

export interface WorkoutStep {
    workoutStepId: string;
    exercise: string;
    order: number;
    sets: WorkoutSet[],
    parentStepId?: string;
}

export interface ICoreProgramDay {
    dayNo: number;
    name?: string;
    workoutSteps: WorkoutStep[];
    programWeekId: string;
}

export interface IApiProgramDay extends ICoreProgramDay {
    _id?: string;
    programId: string;
}

export interface ICoreProgramWeek {
    programId: string;
    order: number;
    notes: string;
    programDays: string[];
}

export interface ICoreProgram {
    name: string;
    description?: string;
    type: ProgramType;
    template: boolean;
    sync: boolean;
}

export interface IApiProgram extends ICoreProgram {
    _id: string;
    client?: string;
    vendorId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IApiProgramWeek extends ICoreProgramWeek {
    _id: string;
}

export interface IApiGetProgram {
    program: IApiProgram;
    programWeeks: IApiProgramWeek[];
    programDays: IApiProgramDay[];
    clientProgram?: IApiClientProgramPopulated;
}

export interface IApiClientProgram {
    _id: string;
    program: string;
    client: string;
    vendor: string;
    startDate: string;
    endDate: string;
    cancelled: boolean;
}

export interface IApiClientProgramPopulated extends Omit<IApiClientProgram, 'client'> {
    client: IApiUser;
}

export interface IApiGetClientProgramSchedule {
    clientPrograms: IApiClientProgram[];
    programs: IApiProgram[];
    programWeeks: IApiProgramWeek[];
    programDays: IApiProgramDay[];
    programDayLogs: IApiProgramLog[];
}

export interface IApiGetUpcomingClientPrograms {
    clientPrograms: IApiClientProgram[];
    programs: IApiProgram[];
}

export interface IProgramLogNote {
    step: number;
    notes: string;
}

export interface ICoreProgramLog {
    clientProgram: string;
    program: string;
    programDay: string;
    client: string;
    notes?: IProgramLogNote;
}

export interface IApiProgramLog extends ICoreProgramLog {
    _id: string;
}

export interface ICoreProgramLogItem {
    workoutSetId: string;
    exercise: string;
    reps?: number;
    weight?: number;
    units: MEASUREMENT_UNIT;
    notes?: string;
}

export interface IApiProgramLogItem extends ICoreProgramLogItem {
    _id: string;
    programLog: string;
}