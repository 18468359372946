import { ActivityCard } from "../../../components/ActivityCard";
import { AppPageHeader } from "../../../components/AppPageHeader";
import { ProfileCard } from "../../../components/ProfileCard";
import { AppPageLayout } from "../../PageLayouts";

export const HomePage: React.FC = () => {
    return (
        <>
            <AppPageLayout title='Dashboard'>
                <AppPageHeader title="Dashboard" />
                <div className="px-4 pt-2 pb-6">
                    <div className="w-full grid gap-4 lg:grid-cols-3">
                        <ProfileCard />
                        <div></div>
                        <ActivityCard />
                    </div>
                </div>
            </AppPageLayout>
        </>
    );
};