import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse } from "./httpClient/mapHttpClientResponse";
import { IApiCoupon } from "./models/Coupon";

export const getCoupon = async (code: string): Promise<HttpClientResponse<IApiCoupon>> => {
    const url = buildUrl(`/api/coupon/${code}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiCoupon>>(url);
    
    return mapHttpClientResponse(response, m => m);
};