import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Button } from "../../components/Buttons";
import { SmartInput } from "../../components/SmartForm";

export interface BusinessFormInputs {
    businessName: string;
}

interface BusinessFormProps {
    onSubmit: (values: BusinessFormInputs) => void,
    onPrev: () => void
}

const schema = yup.object({
    businessName: yup.string().required('Business name is required')
}).required();

export const BusinessForm: React.FC<BusinessFormProps> = ({
    onSubmit,
    onPrev
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<BusinessFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="grid sm:grid-cols-2 gap-4 auto-rows-min flex-1">
                <SmartInput register={register('businessName')} placeholder='Business name' label='Business name' error={errors.businessName?.message} />
            </div>
            <div className='mt-auto grid grid-cols-2 gap-4'>
                <Button type='button' variant='transparent' onClick={onPrev}>Previous</Button>
                <Button type='submit'>Next</Button>
            </div>
        </form>
    );
};