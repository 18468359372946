import clsx from "clsx";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { MdFitnessCenter } from "react-icons/md";
import { IApiExercise } from "../../api/models/Exercise";
import { IconButton } from "../Buttons";

interface ExercisePickerProps {
    exercise: IApiExercise;
    isSelected: boolean;
    onClick(exerciseId: string): void;
    onEdit(exerciseId: string): void;
}

export const ExercisePickerItem: React.FC<ExercisePickerProps> = ({
    exercise,
    isSelected,
    onClick,
    onEdit
}) => {
    const handleEditExercise = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onEdit(exercise._id);
    };

    return (
        <div 
            className={clsx(
                        "flex items-center p-2 transition duration-150 ease-in-out rounded-md group hover:bg-gray-50",
                        isSelected && "bg-blue-200 hover:bg-blue-300")} 
            onClick={() => onClick(exercise._id)}>
            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                <MdFitnessCenter className="w-6 h-6 text-blue-500" />
            </div>
            <div className="ml-4">
                <p className="text-sm font-medium text-gray-900 pointer-events-none">{exercise.name}</p>
            </div>
            <div className="ml-auto opacity-0 transition-all group-hover:opacity-100">
                <IconButton label="Edit exercise" onClick={handleEditExercise}>
                    <FiEdit />
                </IconButton>
            </div>
        </div>
    );
};