import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FormEventHandler, useState } from 'react';
import { Button } from '../../components/Buttons';
import { CouponInput } from '../../components/CouponInput';
import { InputError } from '../../components/Inputs';

export interface SubscriptionFormInputs {
    paymentMethodId: string;
    coupon?: string;
}

interface SubscriptionFormProps {
    onSubmit: (values: SubscriptionFormInputs) => void,
    onPrev: () => void
}

export const SubScriptionForm: React.FC<SubscriptionFormProps> = ({
    onSubmit,
    onPrev
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState<string>();
    const [coupon, setCoupon] = useState<string | undefined>();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        // Block native form submission.
        event.preventDefault();
        setError(undefined);
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        if(!cardElement) {
            return;
        }

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (error) {

          setError(error.message);

        } else if(paymentMethod) {

          const dto: SubscriptionFormInputs = {
              paymentMethodId: paymentMethod.id,
              coupon
          };

          onSubmit(dto);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='flex flex-col h-full'>
            <label className="block text-sm font-medium text-black mb-1">Payment details</label>
            <div className="border border-gray-200 p-4 rounded-md mb-4">
                <CardElement options={{ hidePostalCode: true }} />
            </div>
            <InputError error={error} />
            <div className="mb-4">
                <CouponInput onChange={(coupon) => setCoupon(coupon)} />
            </div>
            <div className="mt-auto grid grid-cols-2 gap-4">
                <Button type='button' variant='transparent' onClick={onPrev}>Previous</Button>
                <Button type='submit' disabled={!stripe}>Sign Up</Button>
            </div>
        </form>
    );
};