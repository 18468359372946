import { FiPlus } from "react-icons/fi";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { IApiGetClientProgramSchedule, IApiProgramDay } from "../../../../api/models/Program";
import { getClientProgramScheduleByClientId, getUpcomingClientProgramsByClientId } from "../../../../api/program";
import { AppPathsAdmin } from "../../../../routes/AppRoutes";
import { ClientDetailProgramContent } from "./ClientDetailProgramContent";
import { ClientDetailProgramSkeleton } from "./ClientDetailProgramSkeleton";
import { ClientDetailProgramUpcoming } from "./ClientDetailProgramUpcoming";

interface ClientDetailProgramParams {
    id: string;
}

const populateRestDays = (clientProgramSchedule: IApiGetClientProgramSchedule): IApiGetClientProgramSchedule => {
    const dayNos = [0, 1, 2, 3, 4, 5, 6];

    const createRestDayObj = (dayNo: number, programWeekId: string): IApiProgramDay => {
        return {
            programId: clientProgramSchedule.programs[0]._id,
            name: 'Rest day',
            dayNo,
            workoutSteps: [],
            programWeekId
        };
    }

    for(const programWeek of clientProgramSchedule.programWeeks) {
        for(const dayNo of dayNos) {
            const programDayExists = Boolean(clientProgramSchedule.programDays.filter(programDay => programDay.programWeekId === programWeek._id).find(programDay => programDay.dayNo === dayNo));

            if(!programDayExists) {
                const restDay = createRestDayObj(dayNo, programWeek._id);
                clientProgramSchedule.programDays.push(restDay);
            }
        }
    }
    clientProgramSchedule.programDays.sort((a, b) => (a.dayNo > b.dayNo) ? 1 : -1);

    return clientProgramSchedule;
};

export const ClientDetailProgram: React.FC = () => {
    const { id } = useParams<ClientDetailProgramParams>();

    const { isLoading, error, data: scheduleData, refetch } = useQuery(`${id}-schedule`, async () => { 
        const result = await getClientProgramScheduleByClientId(id);
        if(!result.isError) {
            return populateRestDays(result.content);
        }   
    });

    const { isLoading: upcomingIsLoading, error: upcomingError, data: upcomingPrograms } = useQuery(`${id}-upcoming`, async () => { 
        const result = await getUpcomingClientProgramsByClientId(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    return (
        <>
            {isLoading && 
                <ClientDetailProgramSkeleton />
            }
            {!isLoading && scheduleData?.clientPrograms && scheduleData.clientPrograms.length > 0 &&
                <ClientDetailProgramContent scheduleData={scheduleData} />
            }
            {!upcomingIsLoading && upcomingPrograms?.clientPrograms && upcomingPrograms?.clientPrograms.length > 0 &&
                <ClientDetailProgramUpcoming clientPrograms={upcomingPrograms.clientPrograms} programs={upcomingPrograms.programs} />
            }
            <Link to={`${AppPathsAdmin.workoutPlanCreate}?client=${id}`} className="group w-full flex flex-col mb-4 items-center justify-center border-dashed border-2 border-gray-200 h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100">
                <FiPlus className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
                <span className="font-medium mt-4 text-lg text-gray-300 transition-colors group-hover:text-blue-400">Create new program</span>
            </Link>
        </>
    );
};