export const ClientDetailLogbookSkeleton: React.FC = () => {
    return (
        <div className="w-full">
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
            <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
        </div>
    );
};