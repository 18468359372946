import { DateTime } from "luxon";
import { FiClock } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IApiProgramLogPopulated } from "../../../api/models/ProgramLog";
import { AppPathsClient } from "../../../routes/AppRoutes";

interface LogbookItemProps {
    logbookItem: IApiProgramLogPopulated
}

export const LogbookItem: React.FC<LogbookItemProps> = ({
    logbookItem
}) => {
    const date = new Date(logbookItem.start);
    const dt = DateTime.fromJSDate(date);

    const duration = DateTime.fromJSDate(new Date(logbookItem.createdAt)).diff(DateTime.fromJSDate(new Date(logbookItem.start))).toFormat("hh:mm:ss");

    return (
        <Link to={AppPathsClient.logbookItem(logbookItem._id)} className="bg-white w-full flex items-center rounded-md p-4 mb-2 transition-all shadow-soft group border-2 border-transparent hover:border-gray-200">
            <div className="w-12 h-12 rounded-md bg-gray-50 flex flex-col items-center justify-center transition-all group-hover:bg-gray-500">
                <span className="text-xxs font-bold uppercase -mb-1 group-hover:text-white">{dt.toFormat('MMM')}</span>
                <span className="text-xl font-medium group-hover:text-white">{dt.toFormat('dd')}</span>
            </div>
            <div className="ml-4">
                <div className="font-medium text-gray-500 transition-all">{logbookItem.programDay?.name ?? 'Workout'}</div>
                {logbookItem.programDay?.workoutSteps?.length > 0 &&<div className="font-medium text-gray-400 transition-all">{logbookItem.programDay.workoutSteps.length} exercises</div>}
            </div>
            <div className="hidden sm:flex ml-auto flex-none bg-gray-100 rounded-md py-2 px-6 items-center">
                <FiClock className="text-blue-400 h-5 w-5 mr-2" />
                <div className="font-medium text-blue-400">{duration}</div>
            </div>
        </Link>
    );
};