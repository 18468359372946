import { CompleteClientProfileForm } from "../../../forms/CompleteClientProfileForm";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

export const WelcomePage: React.FC = () => {
    useDocumentTitle('Complete Profile');
    
    return (
        <div className="w-screen h-screen flex justify-center items-center p-4 bg-gray-100">
            <div className="w-full max-w-xl">
                <h1 className="font-medium text-2xl lg:text-3xl text-center mb-6">Complete Profile</h1>
                <div className="bg-white p-8 rounded-md">
                    <p className="mb-4">Please enter the details below to complete your profile:</p>
                    <CompleteClientProfileForm />
                </div>
            </div>
        </div>
    );
};