import { AppPageLayout } from "../../PageLayouts";
import { useParams } from "react-router";
import ProgramEditor from "../../../components/ProgramEditor";

interface Params {
    id: string;
}

export const EditWorkoutPlanPage: React.FC = () => {
    const { id } = useParams<Params>(); 

    return (
        <AppPageLayout title='Edit Workout Program'>
            {id && 
                <ProgramEditor 
                    programId={id} />
            }
        </AppPageLayout>
    );
};