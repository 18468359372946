import { AppRouter } from './routes/AppRoutes';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { AuthProvider } from './auth';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ExerciseProvider } from './exercise';

import 'react-day-picker/lib/style.css';
import { UIProvider } from './ui';
import { WorkoutProvider } from './workout';
import { config } from './config';

const App: React.FC = () => {
  const stripePromise = loadStripe(config.stripePk);
  const queryClient = new QueryClient();

  if(config.gaMeasurementId) {
    ReactGA.initialize(config.gaMeasurementId);
  }
  
  return (
    <>
    <Toaster position='bottom-right' />
    <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise}>
          <Router>
            <UIProvider>
              <AuthProvider>
                <ExerciseProvider>
                  <WorkoutProvider>
                    <AppRouter />
                  </WorkoutProvider>
                </ExerciseProvider>
              </AuthProvider>
            </UIProvider>
          </Router>
        </Elements>
    </QueryClientProvider>
    </>
  );
};

export default App;
