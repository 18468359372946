import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiActivity } from "./models/Activity";

export const getLatestActivity = async (): Promise<HttpClientResponse<IApiActivity[]>> => {
    const url = buildUrl(`/api/activity/recent`);

    const response = await httpClient.getRequest<ApiListResponse<IApiActivity>>(url);
    
    return mapHttpListResponse(response, m => m);
};