import { DateTime } from 'luxon';
import { FiHome, FiCalendar, FiBookOpen } from 'react-icons/fi';
import { SidebarItem } from '.';
import { AppPathsClient } from '../../routes/AppRoutes';

export const SidebarClientItems: React.FC = () => {
  const dt = DateTime.now();
  const month = dt.toFormat("M");
  const year = dt.toFormat("yyyy");
  
    return (
        <>
          <ul className="m-0 p-4 list-none w-full flex flex-col justify-center md:items-center">
            <SidebarItem label="Home" path={AppPathsClient.dashboard} icon={<FiHome className='w-6 h-6' />} />
            <SidebarItem label="Schedule" path={AppPathsClient.schedule} icon={<FiCalendar className='w-6 h-6' />} />
            <SidebarItem label="Logbook" path={AppPathsClient.logbook(month, year)} icon={<FiBookOpen className="w-6 h-6" />} />
          </ul>
        </>
    );
};