export const ScheduleSkeleton: React.FC = () => {
    return (
        <div className="w-full h-screen-padded flex">
            <div className="flex-1">
                <div className="skeleton-box w-full h-16 bg-white flex justify-center items-center flex-col transform transition-all mb-4"></div>
                <div className="p-6">
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                    <div className="skeleton-box w-full rounded-md h-20 bg-white flex justify-center items-center flex-col transform transition-all hover:shadow-soft hover:-translate-y-1 mb-4"></div>
                </div>
            </div>
            <div className="hidden flex-none w-full max-w-sm lg:block">
                <div className="h-full w-full skeleton-box"></div>
            </div>
        </div>
    );
};