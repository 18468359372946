import { useState } from 'react';
import { forgottenPassword } from '../../../api/authentication';
import LogoFullWhite from '../../../assets/svg/logo-full-white.svg';
import { ForgottenPasswordForm } from '../../../forms/ForgottenPasswordForm';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

export const ForgottenPasswordPage: React.FC = () => {
    const [error, setError] = useState<string>();
    const [resetComplete, setResetComplete] = useState(false);
    useDocumentTitle('Forgotten Password');

    const handleForgottenPassword = async (email: string) => {
        setError(undefined);
        const authResult = await forgottenPassword(email);

        if(!authResult.isError) {
            setResetComplete(true);
        }

        if(authResult.isError) {
            setError('An unknown error occurred. Please try again.');
        }
    };

    return (
        <div className="h-screen w-full bg-gray-200 lg:flex">
            <div className="relative flex-1 h-40 lg:h-full bg-gray-50">
                <img src="https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80" alt="desc" className="hidden lg:block h-full w-full object-cover object-center" />
                <div className="absolute top-0 left-0 w-full h-full p-8 bg-gray-900 bg-opacity-40 bg-blend-multiply flex flex-col items-center justify-center">
                    <img src={LogoFullWhite} alt="PT Sphere Logo" className="h-10" />
                </div>
            </div>
            <div className="flex flex-col flex-1 bg-white h-full px-4">
                {!resetComplete &&
                    <ForgottenPasswordForm error={error} onSubmit={(val) => handleForgottenPassword(val.email)} />
                }
                {resetComplete &&
                    <div className='flex flex-col h-full justify-center items-center'>
                        <p>Please check your email to reset your password.</p>
                    </div>
                }
            </div>
        </div>
    );
};