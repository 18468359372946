import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from 'yup';
import { getVendorClients } from "../../api/clients";
import { assignProgramToClient } from "../../api/program";
import { Button } from "../../components/Buttons";
import { useProgramEditor } from "../../components/ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { SmartSelect } from "../../components/SmartForm";
import { SmartDateInput } from "../../components/SmartForm/SmartDateInput";
import { toastNotification } from "../../components/ToastNotification";

interface AssignProgramFormProps {
    client?: string;
    closeForm: () => void;
}

export interface AssignProgramFormInputs {
    client: string;
    program: string;
    startDate: string;
}

const schema = yup.object({
    client: yup.string().notOneOf(['-1'], 'Client is required').required('Client is required'),
    program: yup.string().required('Program is required'),
    startDate: yup.string().required('Start date is required')
});

export const AssignProgramForm: React.FC<AssignProgramFormProps> = ({
    client,
    closeForm
}) => {
    const { program } = useProgramEditor();

    const { isLoading, error, data: clients = [], refetch } = useQuery('clientList', async () => { 
        const result = await getVendorClients()
        if(!result.isError) {
            return result.content;
        }   
    });

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<AssignProgramFormInputs>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (values: AssignProgramFormInputs) => {
        const { program, client, startDate } = values;
        const result = await assignProgramToClient(program, client, startDate);
    
        if(result.isError) {
            toastNotification({ variant: 'error', title: 'Error assigning workout program', message: result.message });
        }

        if(!result.isError) {
            toastNotification({ variant: 'info', title: 'Workout program assigned', message: `Workout program has been assigned` });
            closeForm();
        }
    };

    const clientOptions = clients.map(client => { 
        return {
            value: client._id,
            label: `${client.firstname} ${client.lastname}`
        }
    });

    const clientDetail = client && clients.find(c => c._id === client);
    const initials = clientDetail &&`${clientDetail?.firstname[0]}${clientDetail?.lastname[0]}`; 
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' {...register('program')} value={program?._id} />
            <div className="w-full mb-4">
                {clientDetail &&
                    <div className="inline-flex items-center bg-white rounded-md shadow-soft px-5 py-4 mb-8">
                        <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden">
                            <div className="w-full h-full flex items-center justify-center text-gray-400 font-medium">
                                <span>{initials}</span>
                            </div>
                        </div>
                        <div className="font-medium ml-4">{clientDetail.firstname} {clientDetail.lastname}</div>
                    </div>
                }
                {!client && 
                    <SmartSelect 
                        register={register('client')} 
                        error={errors.client?.message}
                        label='Select a client'
                        defaultOption='Select a client'
                        options={clientOptions} />
                }
            </div>
            <div className="w-full mb-4">
                <SmartDateInput 
                    label='Start date' 
                    register={register('startDate')}
                    value={getValues('startDate')} 
                    error={errors.startDate?.message} />
            </div>
            <div className="grid grid-cols-2 gap-4">
                <Button type='button' variant='transparent' onClick={closeForm}>Cancel</Button>
                <Button type='submit' variant='primary'>Assign</Button>
            </div>
        </form>
    );
};