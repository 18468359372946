import clsx from "clsx";

type LoaderVariants = 'Primary';

interface Props {
    variant?: string;
    size?: "sm"
}

export const Loader: React.FC<Props> = ({
    variant,
    size
}) => {
    return (
        <span className={clsx("loader", variant === 'Primary' && "loader--primary", size === "sm" && "loader--small")}></span>
    );
};