interface Props {
    label: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    tabIndex?: number;
}

export const IconButton: React.FC<Props> = ({
    label,
    onClick,
    tabIndex,
    children
}) => {
    return (
        <button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50" onClick={onClick} tabIndex={tabIndex}>
            {children}
            <span className="sr-only">{label}</span>
        </button>
    );
};