import { buildUrl, httpClient } from "./httpClient";
import { IApiAuthLoginResponse } from "./models/Authentication";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse } from "./httpClient/mapHttpClientResponse";
import { SignUpFormInputs } from "../pages/PublicPages/SignUp/SignUp";

export const authLogin = async (email: string, password: string): Promise<HttpClientResponse<IApiAuthLoginResponse>> => {
    const url = buildUrl('/api/sessions');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiAuthLoginResponse>>(url, { email, password });
    
    return mapHttpClientResponse(response, m => m);
};

export const authLogout = async (): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/sessions');

    const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const vendorSignUp = async (signUpDto: SignUpFormInputs): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/vendor');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, signUpDto);

    return mapHttpClientResponse(response, m => m);
};

export const forgottenPassword = async (email: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/users/forgotten-password');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, { email });
    
    return mapHttpClientResponse(response, m => m);
};