import { DateTime } from "luxon";
import { IApiUser } from "../../../../api/models/User";
import { AppBadge } from "../../../../components/AppBadge";
import { IUser } from "../../../../models/User";

interface ClientDetailOverviewProps {
    client: IUser;
}

export const ClientDetailOverview: React.FC<ClientDetailOverviewProps> = ({
    client
}) => {

    const formattedDob = client.dob && DateTime.fromISO(client.dob).toFormat("dd/MM/yyyy");
    const gender = client.gender === 1 ? 'Male' : 'Female';
    const isPending = client.confirmationCode;

    return (
        <div className="">
            <div className="bg-white rounded-md p-8 relative">
                <h2 className="font-medium text-2xl mb-8">Details</h2>
                <div className="mb-4">
                    <div className="font-medium text-sm opacity-30">Date of birth</div>
                    <div className="font-medium">{formattedDob ?? '--/--/----'}</div>
                </div>
                <div className="mb-4">
                    <div className="font-medium text-sm opacity-30">Gender</div>
                    <div className="font-medium">{client.gender ? gender : '------'}</div>
                </div>
                <div className="mb-4">
                    <div className="font-medium text-sm opacity-30">Email address</div>
                    <div className="font-medium">{client.email}</div>
                </div>
                <div className="mb-4">
                    <div className="font-medium text-sm opacity-30">Telephone</div>
                    <div className="font-medium">{client.phone ?? '-----------'}</div>
                </div>
                <div className="absolute top-4 right-4">
                    {isPending && <AppBadge variant="warning">Pending</AppBadge>}
                    {!isPending && <AppBadge variant="success">Active</AppBadge>}
                </div>
            </div>
        </div>
    );
};