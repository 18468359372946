import LogoFull from '../../assets/svg/logo-full.svg';

export const TestPage: React.FC = () => {
    return (
        <>
            <aside className="fixed top-0 left-0 w-full max-w-xs h-screen bg-gray-50">
                <a href="#0" className="relative flex justify-center items-center p-4">
                    <img src={LogoFull} alt="PT Sphere Logo" className="relative h-6 lg:h-8 w-auto" />
                </a>
            </aside>
        </>
    );
};