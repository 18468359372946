import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { UIContext } from "./UIContext";

export const UIProvider: React.FC = ({
    children
}) => {
    const location = useLocation();
    const [navIsOpen, setNavIsOpen] = useState(false);

    useEffect(() => {
        setNavIsOpen(false);
    }, [location]);

    return (
        <UIContext.Provider value={{
            navIsOpen,
            setNavIsOpen
        }}>
            {children}
        </UIContext.Provider>
    )
};