import clsx from "clsx";
import { HTMLProps } from "react";

interface InputProps extends Omit<HTMLProps<HTMLInputElement>, 'className'> {
    className?: string;
}

export const Input: React.FC<InputProps> = ({
    className,
    ...props
}) => {
    return (
        <input
            className={clsx("h-12 border border-gray-200 px-2 rounded-md", className)}
            {...props}
        />
    );
}