import { Route, Switch } from "react-router";
import { ClientDetailLogbook, ClientDetailOverview, ClientDetailProgram } from ".";
import { IUser } from "../../../models/User";

interface ClientDetailRouterProps {
    client: IUser;
}

export const AdminClientDetailPaths = {
    overview: (id: string) => `/clients/${id}`,
    program: (id: string) => `/clients/${id}/program`,
    logbook: (id: string, month: string, year: string) => `/clients/${id}/logbook/items/${month}/${year}`
}

export const ClientDetailRouter: React.FC<ClientDetailRouterProps> = ({
    client
}) => {
    return (
        <>
            <Switch>
                <Route path={AdminClientDetailPaths.overview(':id')} strict exact>
                    <ClientDetailOverview client={client} />
                </Route>
                <Route path={AdminClientDetailPaths.program(':id')} strict exact>
                    <ClientDetailProgram />
                </Route>
                <Route path={AdminClientDetailPaths.logbook(':id', ':month', ':year')} strict exact>
                    <ClientDetailLogbook />
                </Route>
            </Switch>
        </>
    );
};