import clsx from "clsx";
import { ButtonHTMLAttributes} from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'info' | 'transparent' | 'gray' | 'danger';
    link?: string
    size?: 'small'
}

export const Button: React.FC<ButtonProps> = ({
    variant = 'primary',
    size,
    link,
    children,
    ...props
}) => {
    const buttonVariantStyles = clsx(
        "flex items-center justify-center w-full text-base font-medium text-center transition duration-500 ease-in-out transform rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        variant === 'primary' && "bg-blue-400 text-white hover:bg-blue-500",
        variant === 'transparent' && "bg-transparent text-base",
        variant === 'gray' && "bg-gray-100 text-base hover:bg-gray-200",
        variant === 'info' && "text-blue-900 bg-blue-100 hover:bg-blue-200",
        variant === 'danger' && "text-red-600 bg-red-200 hover:bg-red-300",
        size === 'small' ? "py-2 px-6" : "py-3 px-10",
        props.disabled && 'opacity-40 cursor-not-allowed'
    );

    if(link) return (
        <Link to={link} className={buttonVariantStyles}>
            {children}
        </Link>
    );

    return (
        <button 
            className={buttonVariantStyles}
            {...props}
        >
        {children}
        </button>
    );
};