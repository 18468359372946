import { useEffect, useState } from "react";
import { IUser, ROLES } from "../models/User";
import { AuthContext } from "./AuthContext";
import authClient from "./authClient";
import { FullScreenLoader } from "../components/Loader/FullScreenLoader";
import { useHistory } from "react-router-dom";
import { WelcomePage } from "../pages/ClientPages/Welcome";

export const AuthProvider: React.FC = ({
    children
}) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        const session = authClient.loadSession();

        if(session.isLoggedIn) {
            setIsLoggedIn(true);
            setUser(session.user);
        }

        setIsLoading(false);
    }, []);

    const handleLogIn = async (email: string, password: string) => {
        const authResult = await authClient.logIn(email, password);

        if(!authResult.isError) {
            setUser(authResult.user);
            setIsLoggedIn(true);
        }

        return authResult;
    };

    const handleLogOut = async () => {
        const logoutResult = await authClient.logOut();

        if(!logoutResult.isError) {
            setUser(undefined);
            setIsLoggedIn(false);
            history.push("/");
        }
    };

    const showClientWelcome = user?.role === ROLES.CLIENT && !user.dob;
    const content = showClientWelcome ? 
    (<WelcomePage />)
    :
    (<>{children}</>);

    useEffect(() => {
        if(user) {
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user]);

    return (
        <AuthContext.Provider value={{
            logIn: handleLogIn,
            logOut: handleLogOut,
            updateUser: setUser,
            isLoggedIn,
            isLoading,
            user: user
        }}>
            {isLoading ? <FullScreenLoader /> : content}
        </AuthContext.Provider>
    );
};