import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

interface RouteChangeScrollToTopProps {
    history: History
}

function RouteChangeScrollToTop({ history }: RouteChangeScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(RouteChangeScrollToTop);