import { MdFitnessCenter } from "react-icons/md";
import { WorkoutEditorSet } from "..";
import { Draggable } from 'react-beautiful-dnd';
import { WorkoutStep } from "../../../api/models/Program";
import { useExercise } from "../../../exercise/ExerciseContext";
import clsx from "clsx";
import { WorkoutEditorStepMenu } from "../WorkoutEditorStep/WorkoutEditorStepMenu";
import { Button } from "../../Buttons";
import { useProgramEditor } from "../../ProgramEditor/ProgramEditorContext/ProgramEditorContext";

interface Props {
    workoutStep: WorkoutStep;
}

export const WorkoutEditorSupersetStep: React.FC<Props> = ({
    workoutStep
}) => {
    const { getExerciseById } = useExercise();
    const { addSet } = useProgramEditor();

    const exercise = getExerciseById(workoutStep.exercise);
    
    return (
                <div className={clsx("mt-4")} key={workoutStep.workoutStepId}>
                    <div className="p-2 rounded-md group bg-white">
                        <div className="flex items-center">
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                                <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                            </div>
                            <div className="ml-4">
                                <p className="font-medium text-gray-900">{exercise?.name}</p>
                            </div>
                            <div className="ml-auto flex items-center">
                                <WorkoutEditorStepMenu workoutStep={workoutStep} onAddSuperset={(workoutStepId) => null} />
                            </div>
                        </div>
                        {workoutStep.sets.map(workoutSet =>
                            <WorkoutEditorSet 
                                workoutSet={workoutSet}
                                workoutStepId={workoutStep.workoutStepId}
                                key={workoutSet.workoutSetId}
                                exercise={exercise} />  
                        )}
                        <div className="mt-4">
                            <Button variant="gray" size="small" onClick={() => addSet(workoutStep.workoutStepId)}>Add Set +</Button>
                        </div>
                    </div>
                </div>
    );
};