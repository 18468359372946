import { authLogin, authLogout } from "../api/authentication";
import { IUser } from "../models/User";

interface AuthFailureResult {
    isError: true,
    error: string,
    status?: number
}

interface AuthSuccessResult {
    isError: false,
    user: IUser
}

export type AuthResult = AuthFailureResult | AuthSuccessResult;

const createAuthClient = () => {
    const logIn = async (email: string, password: string): Promise<AuthResult> => {
        const authResult = await authLogin(email, password);

        if(authResult.isError) {
            return {
                isError: true,
                error: authResult.message,
                status: authResult.statusCode
            }
        }

        localStorage.setItem('accessToken', authResult.content.accessToken);
        localStorage.setItem('refreshToken', authResult.content.refreshToken);
        localStorage.setItem('user', JSON.stringify(authResult.content.user));

        return {
            isError: false,
            user: authResult.content.user
        };
    };

    const logOut = async () => {
        await authLogout();
        localStorage.clear();

        return {
            isError: false
        };
    };

    const getTokens = () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        return {
            accessToken,
            refreshToken
        };
    };

    const getUser = () => {
        const user = localStorage.getItem('user');

        return user ? JSON.parse(user) : undefined;
    };

    const loadSession = () => {
        const user = getUser();

        return {
            isLoggedIn: !!user,
            user
        };
    };

    return {
        logIn,
        logOut,
        getTokens,
        getUser,
        loadSession
    };
};

const authClient = createAuthClient();

export default authClient;