import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from 'yup';
import { Button } from "../../components/Buttons";
import { InputError } from "../../components/Inputs";
import { SmartInput } from "../../components/SmartForm";
import { AppPathsPublic } from "../../routes/AppRoutes";

export interface ForgottenPasswordFormInputs {
    email: string;
}

interface ForgottenPasswordFormProps {
    error?: string,
    onSubmit: (values: ForgottenPasswordFormInputs) => void
}

const schema = yup.object({
    email: yup.string().email('Please enter a valid email address').required('Email address is required'),
}).required();

export const ForgottenPasswordForm: React.FC<ForgottenPasswordFormProps> = ({
    error,
    onSubmit
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<ForgottenPasswordFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <h1 className="text-3xl font-medium mb-4">Forgotten Password</h1>
            <p className="mb-8">Enter your email address below to reset your password:</p>
            <div className="relative w-full max-w-sm">
                <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
            </div>
            <div className='mt-4 w-full max-w-sm'>
                <Button type='submit'>Submit</Button>
                {error && <div className="mt-4">
                    <InputError error={error} />
                </div>}
            </div>
            <div className="relative my-4 w-full max-w-sm">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-neutral-600 bg-white"> Not got an account? </span>
                </div>
            </div>
            <div className='w-full max-w-sm'>
                <Button link={AppPathsPublic.signUp} type='button' variant='gray'>Sign up</Button>
            </div>
        </form>
    );
};