import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Button } from "../../components/Buttons";
import { SmartInput } from "../../components/SmartForm";

export interface PasswordFormInputs {
    password: string;
    confirmPassword: string;
}

interface PasswordFormProps {
    onSubmit: (values: PasswordFormInputs) => void,
    onPrev: () => void
}

const schema = yup.object({
    password: yup.string()
                .min(8, 'Password  must be at least 8 characters long')
                .matches(/^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g, 'Password can only contain letters, numbers and special characters')
                .required('Please enter a password'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
}).required();

export const PasswordForm: React.FC<PasswordFormProps> = ({
    onSubmit,
    onPrev
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<PasswordFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="grid sm:grid-cols-2 gap-4 auto-rows-min flex-1">
                <SmartInput register={register('password')} type='password' placeholder='Password' label='Password' error={errors.password?.message} />
                <SmartInput register={register('confirmPassword')} type='password' placeholder='Confirm password' label='Confirm password' error={errors.confirmPassword?.message} />
            </div>
            <div className='mt-auto grid grid-cols-2 gap-4'>
                <Button type='button' variant='transparent' onClick={onPrev}>Previous</Button>
                <Button type='submit'>Next</Button>
            </div>
        </form>
    );
};