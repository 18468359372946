import clsx from "clsx";
import { DateTime } from "luxon";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IApiClientProgram, IApiProgramDay, IApiProgramWeek } from "../../../../api/models/Program";
import { Button } from "../../../../components/Buttons";
import { AppPathsAdmin, AppPathsClient } from "../../../../routes/AppRoutes";

enum ItemType {
    REST_DAY,
    WORKOUT_DAY
}

interface ClientDetailProgramItemContainerProps {
    type: ItemType;
    dayId: string;
    isComplete: boolean;
}

interface ClientDetailProgramItemProps {
    day: IApiProgramDay;
    week: IApiProgramWeek;
    clientProgram: IApiClientProgram;
    programLog?: string;
}

const ClientDetailProgramItemContainer: React.FC<ClientDetailProgramItemContainerProps> = ({ type, dayId, isComplete, children }) => {

    if(type === ItemType.REST_DAY) {
        return (
            <div className="bg-white cursor-not-allowed w-full flex items-center rounded-md p-4 mb-2 transition-all bg-opacity-40 shadow-none">
                {children}
            </div>
        );
    }

    return (
        <>
        {isComplete ?
            <div className="bg-white w-full flex items-center rounded-md p-4 mb-2 transition-all shadow-soft">
                {children}
            </div>
        :
            <Link to={AppPathsClient.workout(dayId, '0')} className="bg-white w-full flex items-center rounded-md p-4 mb-2 transition-all shadow-soft group border-2 border-transparent hover:border-gray-200">
                {children}
            </Link>
        }
        </>
    );
};

export const ClientDetailProgramItem: React.FC<ClientDetailProgramItemProps> = ({
    day,
    week,
    clientProgram,
    programLog
}) => {
    const date = new Date(clientProgram.startDate);
    date.setDate(date.getDate() + ((week.order * 7) + day.dayNo));
    const dt = DateTime.fromJSDate(date);
    const isToday = dt.hasSame(DateTime.local(), "day");

    const type = day.workoutSteps.length === 0 ? ItemType.REST_DAY : ItemType.WORKOUT_DAY;
    
    return (
        <ClientDetailProgramItemContainer type={type} dayId={day._id ?? '0'} isComplete={Boolean(programLog)}>
            <div className={clsx("w-12 h-12 rounded-md bg-gray-50 flex flex-col items-center justify-center transition-all group-hover:bg-gray-500", isToday && "bg-blue-400")}>
                <span className={clsx("text-xxs font-bold uppercase -mb-1 group-hover:text-white", isToday ? "text-white": "text-gray-400")}>{dt.toFormat('MMM')}</span>
                <span className={clsx("text-xl font-medium group-hover:text-white", isToday ? "text-white" : "text-gray-500")}>{dt.toFormat('dd')}</span>
            </div>
            <div className="ml-4">
                <div className="font-medium text-gray-500 transition-all">{day.name ?? `Workout ${day.dayNo + 1}`}</div>
                {day.workoutSteps.length > 0 &&<div className="font-medium text-gray-400 transition-all">{day.workoutSteps.length} exercises</div>}
            </div>
            <div className="ml-auto flex items-center">
                {programLog &&
                    <>
                        <div className="mr-4">
                            <Button link={AppPathsAdmin.clientLogbookItem(clientProgram.client, programLog)} variant="gray" size="small">View log</Button>
                        </div>
                        <div className="w-10 h-10 rounded-full bg-green-400 flex items-center justify-center">
                            <FiCheck className="text-white w-6 h-6" />
                        </div>
                    </>
                }
            </div>
        </ClientDetailProgramItemContainer>
    );
};