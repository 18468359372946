import { Menu, Transition } from "@headlessui/react";
import { MouseEvent, Fragment, useState } from "react";
import { FiArchive, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { archiveVendorClient, deleteVendorClient } from "../../api/clients";
import { Button } from "../Buttons";
import { Modal } from "../Modal";
import { toastNotification } from "../ToastNotification";

interface UserGridItemMenuProps {
    userId: string;
    refetch(): void;
}

export const UserGridItemMenu: React.FC<UserGridItemMenuProps> = ({
    userId,
    refetch
}) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const archiveClient = async () => {
        const result = await archiveVendorClient(userId);

        if(!result.isError) {
            toastNotification({
                variant: 'info',
                title: 'Client archived',
                message: 'Client has been archived'
            });
            refetch();
        }
    };

    const deleteClient = async () => {
        const result = await deleteVendorClient(userId);

        if(!result.isError) {
            toastNotification({
                variant: 'info',
                title: 'Client removed',
                message: 'Client has been removed'
            });
            refetch();
        }
    };

    const handleArchiveClient = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        archiveClient();
    };

    const handleDeleteClient = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        deleteClient();
    };

    const handleConfirmDeleteClient = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setDeleteConfirmation(true);
    };

    return (
        <>
        <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                <FiMoreVertical
                    className="w-5 h-5"
                    aria-hidden="true"
                    />
                <span className="sr-only">Options</span>
            </Menu.Button>
            </div>
            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={handleArchiveClient}
                        >
                            <FiArchive 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Archive client
                        </button>
                        )}
                    </Menu.Item>
                </div>
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-red-200 text-red-600' : 'text-red-600'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={handleConfirmDeleteClient}
                        >
                            <FiTrash2 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Remove client
                        </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
        <Modal 
            isOpen={deleteConfirmation} 
            closeModal={() => setDeleteConfirmation(false)} 
            title='Remove client' 
            caption={`Removing a client is permanent. Are you sure you want to remove this client?`}>
                <div className='mt-4 grid grid-cols-2 gap-4'>
                    <Button type='button' variant='transparent' onClick={() => setDeleteConfirmation(false)}>Cancel</Button>
                    <Button type='button' variant='danger' onClick={handleDeleteClient}>Delete</Button>
                </div>
        </Modal>
        </>
    );
};