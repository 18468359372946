import clsx from "clsx";
import toast from "react-hot-toast";
import { FiCheckCircle, FiXOctagon, FiAlertOctagon, FiInfo, FiX } from 'react-icons/fi';

interface ToastNotificationParams {
    variant?: 'success' | 'error' | 'warning' | 'info',
    title?: string,
    message?: string
}

export const toastNotification = ({ variant, title, message }: ToastNotificationParams) => toast.custom(t => (
        <div
              className={clsx(
                  'max-w-md w-full bg-white shadow-lg rounded-md pointer-events-auto flex ring-1 ring-black ring-opacity-5',
                  t.visible ? 'animate-enter' : 'animate-leave',
                  variant === 'success' && 'bg-green-50 border border-green-500',
                  variant === 'error' && 'bg-red-50 border border-red-500',
                  variant === 'warning' && 'bg-yellow-50 border border-yellow-500',
                  variant === 'info' && 'bg-blue-50 border border-blue-500',
                  )}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                      {variant === 'success' && <FiCheckCircle className="h-8 w-8 text-green-500 rounded-full" />}
                      {variant === 'error' && <FiXOctagon className="h-8 w-8 text-red-500 rounded-full" />}
                      {variant === 'warning' && <FiAlertOctagon className="h-8 w-8 text-yellow-500 rounded-full" />}
                      {variant === 'info' && <FiInfo className="h-8 w-8 text-blue-500 rounded-full" />}
                  </div>
                  <div className="ml-3 flex-1">
                      {title &&
                        <p className="text-sm font-medium text-gray-900">
                            {title}
                        </p>
                      }
                    {message &&
                        <p className="mt-1 text-sm text-gray-500">
                            {message}
                        </p>
                    }
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.remove(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <FiX className="h-4 w-4" />
                </button>
              </div>
            </div>
));