import { useState } from "react";
import { useMutation } from "react-query";
import { getCoupon } from "../../api/coupon";
import { Button } from "../Buttons";
import { Input } from "../Inputs/Input";

interface CouponInputProps {
    onChange(coupon: string | undefined): void;
}

export const CouponInput: React.FC<CouponInputProps> = ({
    onChange
}) => {
    const [coupon, setCoupon] = useState("");
    const [isValid, setIsValid] = useState<boolean>();
    const [percentage, setPercentage] = useState<number>();

    const couponRequest = useMutation(`${coupon}-code`, async (coupon: string) => {
        const result = await getCoupon(coupon);
        
        if(result.isError) {
            setIsValid(false);

            onChange(undefined);
        }

        if(!result.isError) {
            setIsValid(true);
            setPercentage(result.content.percentage);
            onChange(result.content.stripeCode);
        } 
    });
    
    const handleCouponApply = () => {
        setIsValid(undefined);
        couponRequest.mutate(coupon);
    };

    return (
        <>
            <label htmlFor="coupon" className="block text-sm font-medium text-black mb-1">Coupon</label>
            <div className="flex items-center">
                <Input id="coupon" type="text" placeholder="Coupon..." value={coupon} onChange={(e) => setCoupon(e.currentTarget.value.toUpperCase())} />
                <div className="ml-4 flex-none">
                    <Button type="button" variant="gray" onClick={handleCouponApply}>Apply</Button>
                </div>
            </div>
            {isValid && 
                <div className="mt-4 bg-green-100 text-green-600 border border-green-500 rounded-md p-4">
                    Coupon applied: {percentage}% OFF
                </div>
            }
            {typeof(isValid) === 'boolean' && !isValid && 
                <div className="mt-4 bg-red-100 text-red-600 border border-red-500 rounded-md p-4">
                    Coupon is invalid
                </div>
            }
        </>
    );
};