import { createContext, useContext } from "react";
import { IUser } from "../models/User";
import { AuthResult } from "./authClient";

export interface IAuthContext {
    logIn: (email: string, password: string) => Promise<AuthResult>;
    logOut: () => Promise<void>;
    updateUser: (user: IUser) => void;
    isLoggedIn: boolean,
    isLoading: boolean,
    user?: IUser;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const AuthContext = createContext<IAuthContext>({
    logIn: placeholderFunc,
    logOut: placeholderFunc,
    updateUser: placeholderFunc,
    isLoggedIn: false,
    isLoading: true
});

export const useAuth: () => IAuthContext = () => useContext(AuthContext);