import { useQuery } from "react-query";
import { getExercises, getExerciseVideos } from "../api/exercise";
import { IApiExercise, IApiExerciseVideo } from "../api/models/Exercise";
import { ExerciseContext } from "./ExerciseContext";

export const ExerciseProvider: React.FC = ({
    children
}) => {
    const { isLoading, error, data: exerciseList = [], refetch } = useQuery('exerciseList', async () => { 
        const result = await getExercises()
        if(!result.isError) {
            return result.content;
        }   
    });

    const { isLoading: exerciseVideoIsLoading, error: exerciseVideoError, data: exerciseVideoList = [], refetch: exerciseVideoRefetch } = useQuery('exerciseVideoList', async () => { 
        const result = await getExerciseVideos()
        if(!result.isError) {
            return result.content;
        }   
    });

    const getExerciseById = (exerciseId: string): IApiExercise | null => {
        const exercise = exerciseList.find(exercise => exercise._id === exerciseId);

        if (exercise === null) refetchItems();

        return exercise ?? null;
    };

    const getExerciseVideoById = (exerciseId: string): IApiExerciseVideo | null => {
        const exerciseVideos = exerciseVideoList.filter(exerciseVideo => exerciseVideo.exercise === exerciseId);

        if(exerciseVideos.length > 0) {
            const vendorSpecificVideo = exerciseVideos.find(exerciseVideo => exerciseVideo.vendor !== undefined);

            return vendorSpecificVideo ?? exerciseVideos[0];
        }

        return null;
    };

    const refetchItems = () => {
        refetch();
        exerciseVideoRefetch();
    }

    return (
        <ExerciseContext.Provider value={{
            exerciseList,
            exerciseVideoList,
            isLoading,
            getExerciseById,
            getExerciseVideoById,
            refetch: refetchItems
        }}>
            {children}
        </ExerciseContext.Provider>
    )
};