import { useHistory, useParams } from 'react-router';
import { clientPasswordConfirmation } from '../../../api/clients';
import LogoFullWhite from '../../../assets/svg/logo-full-white.svg';
import { toastNotification } from '../../../components/ToastNotification';
import { ClientConfirmationForm } from '../../../forms/ClientConfirmationForm';
import { ClientConfirmationFormInputs } from '../../../forms/ClientConfirmationForm/ClientConfirmationForm';
import { useQuery } from '../../../hooks/useQuery';
import { AppPathsClient } from '../../../routes/AppRoutes';

interface Params {
    confirmationCode?: string;
}

export const ClientConfirmationPage: React.FC = () => {
    const { confirmationCode } = useParams<Params>();
    const query = useQuery();
    const history = useHistory();
    const isReset = query.get('reset');

    if(!confirmationCode) return null;
    
    const handleSubmit = async (values: ClientConfirmationFormInputs) => {
        const result = await clientPasswordConfirmation(confirmationCode, values.password);

        if(!result.isError) {
            toastNotification({ variant: 'success', title: 'Password Updated', message: `Your password has now been updated. You can now sign in!` });
            history.push(AppPathsClient.dashboard);
        }
    };

    return (
        <div className="h-screen w-full bg-gray-200 lg:flex">
            <div className="relative flex-1 h-40 lg:h-full bg-gray-50">
                <img src="https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80" alt="desc" className="hidden lg:block h-full w-full object-cover object-center" />
                <div className="absolute top-0 left-0 w-full h-full p-8 bg-gray-900 bg-opacity-40 bg-blend-multiply flex flex-col items-center justify-center">
                    <img src={LogoFullWhite} alt="PT Sphere Logo" className="h-10" />
                </div>
            </div>
            <div className="flex flex-col flex-1 bg-white h-full px-4">
                <ClientConfirmationForm isReset={!!isReset} onSubmit={handleSubmit} />
            </div>
        </div>
    );
};