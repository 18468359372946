import clsx from "clsx";
import { IApiExercise } from "../../../api/models/Exercise";
import { MEASUREMENT_TYPE, MEASUREMENT_UNIT, WorkoutSet } from "../../../api/models/Program";
import { useProgramEditor } from "../../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { WorkoutEditorSetMenu } from "./WorkoutEditorSetMenu";

interface Props {
    workoutSet: WorkoutSet;
    workoutStepId: string;
    exercise: IApiExercise | null;
}

export const WorkoutEditorSet: React.FC<Props> = ({
    workoutSet,
    workoutStepId,
    exercise
}) => {
    const { editProgramSet } = useProgramEditor();

    return (
        <div className="flex items-center flex-none w-full mt-2 pt-2 border-t border-gray-50">
            <div className="font-medium text-gray-400 w-10 h-10 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-50">{workoutSet.order + 1}.</div>
            {workoutSet.measurementType === MEASUREMENT_TYPE.WEIGHT &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.reps} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'reps', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                </div>
                <div className="flex items-center ml-4">
                    {workoutSet.weight?.units !== MEASUREMENT_UNIT.BODYWEIGHT &&
                        <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.weight?.value} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'weight', parseFloat(e.currentTarget.value))} />
                    }
                    <select className="text-sm font-medium text-gray-500 ml-2 bg-transparent border-none" name="units" value={workoutSet.weight?.units} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'units', parseInt(e.currentTarget.value))}>
                        <option value={MEASUREMENT_UNIT.IMPERIAL}>LBS</option>
                        <option value={MEASUREMENT_UNIT.METRIC}>KG</option>
                        <option value={MEASUREMENT_UNIT.BODYWEIGHT}>BODYWEIGHT</option>
                    </select>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.RPE &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.reps} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'reps', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} max={10} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.rpe} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'rpe', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">RPE</div>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.PRECENTAGE &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.reps} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'reps', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.percentage} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'percentage', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">%1RM</div>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.TIME &&
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.time} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'time', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Seconds</div>
                </div>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.INTERVAL &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.intOn} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalOn', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">ON</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.intOff} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalOff', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">OFF</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.rounds} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalRounds', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">ROUND(S)</div>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_DISTANCE &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.speed} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'speed', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Speed (KM/H)</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.distance} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'distance', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Distance (KM)</div>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_TIME &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.speed} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'speed', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Speed (KM/H)</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.time} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'time', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Time (s)</div>
                </div>
                </>
            }
            {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_INTERVAL &&
                <>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} step={0.01} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.speed} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'speed', parseFloat(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">Speed (KM/H)</div>
                </div>
                <div className="flex items-center">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.intOn} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalOn', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">ON</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.intOff} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalOff', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">OFF</div>
                </div>
                <div className="flex items-center ml-4">
                    <input type='number' onFocus={e => e.currentTarget.select()} min={0} onWheel={e => e.currentTarget.blur()} className={clsx("h-12 w-20 border border-gray-200 px-2 rounded-md")} value={workoutSet.interval?.rounds} onChange={(e) => editProgramSet(workoutStepId, workoutSet.workoutSetId, 'intervalRounds', parseInt(e.currentTarget.value))} />
                    <div className="text-sm font-medium text-gray-500 ml-2">ROUND(S)</div>
                </div>
                </>
            }
            <div className="ml-auto flex items-center">
                <WorkoutEditorSetMenu workoutStepId={workoutStepId} workoutSet={workoutSet} exercise={exercise} />
            </div>
        </div>
    );
};