import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import ReactGA from 'react-ga4';

interface PageViewAnalyticsProps {
    history: History
}

function PageViewAnalytics({ history }: PageViewAnalyticsProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
        ReactGA.send("pageview");
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(PageViewAnalytics);