import { AppPageHeader } from "../../../components/AppPageHeader";
import { AvatarUpdateCard } from "../../../components/AvatarUpdateCard";
import { ProfileUpdateCard } from "../../../components/ProfileUpdateCard";
import { AppPageLayout } from "../../PageLayouts";

export const ProfilePage: React.FC = () => {
    return (
        <AppPageLayout title="Profile">
            <AppPageHeader title="Profile" />
            <div className="px-6 pt-2 pb-6">
                <div className="w-full grid gap-4 lg:grid-cols-3">
                    <AvatarUpdateCard />
                    <div className="lg:col-span-2">
                        <ProfileUpdateCard />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};