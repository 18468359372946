import Axios, { AxiosError, AxiosResponse } from 'axios';
import { string } from 'yup/lib/locale';
import authClient from '../../auth/authClient';

export interface HttpClientSuccessResponse<T> {
    isError: false,
    statusCode: number,
    content: T
}

export interface HttpClientFailureResponse {
    isError: true,
    statusCode?: number,
    message: string
}

export type HttpClientResponse<T> = HttpClientSuccessResponse<T> | HttpClientFailureResponse;

interface IAxiosOptions {
    headers: {
        Authorization?: string,
        'x-refresh'?: string
    },
    validateStatus: ((status: number) => boolean) | null | undefined;
    contentType?: string;
}

function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}

function handleError<T>(url: string, error: any): HttpClientResponse<T> {
    const tokenExpiredCodes = [403]
    if(tokenExpiredCodes.includes(error.response.status)) {
        authClient.logOut();
        window.location.replace('/');
    }

    if(isAxiosError(error)) {
        if(error.response) {
            const message = `Request to ${url} failed with status ${error.response.status}.`;
            return {
                isError: true,
                statusCode: error.response.status,
                message
            };
        } else if (error.request) {
            const message = `Request to ${url} failed, no response received.`;
            return {
                isError: true,
                message
            };
        } else {
            const message = `Request failed with unknown error: ${error.message}`;
            return {
                isError: true,
                message
            }
        }
    }

    throw error;
}

const buildDefaultHeaders = () => {
    const { accessToken, refreshToken } = authClient.getTokens();

    const headers = {
        'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
        'x-refresh': refreshToken ? refreshToken : undefined
    };

    return headers;
};

const handleRefreshAccessToken = (response: AxiosResponse<unknown, any>) => {
    const refreshedAccessToken = response.headers['x-access-token'];


    if(refreshedAccessToken) {
        localStorage.setItem('accessToken', refreshedAccessToken);
    }
};

export async function getRequest<TResponse>(url: string, allowedNon200Statuses: number[] = []): Promise<HttpClientResponse<TResponse>> {
    try {
        const axiosOptions: IAxiosOptions = {
            headers: buildDefaultHeaders(),
            validateStatus: status => (status >= 200 && status <= 299) || allowedNon200Statuses.includes(status)
        };

        const response = await Axios.get(url, axiosOptions);

        handleRefreshAccessToken(response);

        const responseData = response.data as TResponse;

        return {
            isError: false,
            statusCode: response.status,
            content: responseData
        };

    } catch (error: any) {
        return handleError<TResponse>(url, error);
    }
}

export async function postRequest<TResponse>(url: string, payload?: unknown, contentType?: string): Promise<HttpClientResponse<TResponse>> {
    try {
        const axiosOptions: IAxiosOptions = {
            headers: buildDefaultHeaders(),
            validateStatus: status => (status >= 200 && status <= 299),
        };

        if(contentType) {
            axiosOptions.contentType = contentType;
        }

        const response = await Axios.post(url, payload, axiosOptions);

        handleRefreshAccessToken(response);

        const responseData = response.data as TResponse;

        return {
            isError: false,
            statusCode: response.status,
            content: responseData
        };

    } catch (error) {
        return handleError<TResponse>(url, error);
    }
}

export async function putRequest<TResponse>(url: string, payload?: unknown): Promise<HttpClientResponse<TResponse>> {
    try {
        const axiosOptions: IAxiosOptions = {
            headers: buildDefaultHeaders(),
            validateStatus: status => (status >= 200 && status <= 299)
        };

        const response = await Axios.put(url, payload, axiosOptions);

        handleRefreshAccessToken(response);

        const responseData = response.data as TResponse;

        return {
            isError: false,
            statusCode: response.status,
            content: responseData
        };

    } catch (error) {
        return handleError<TResponse>(url, error);
    }
}

export async function deleteRequest<TResponse>(url: string): Promise<HttpClientResponse<TResponse>> {
    try {
        const axiosOptions: IAxiosOptions = {
            headers: buildDefaultHeaders(),
            validateStatus: status => (status >= 200 && status <= 299)
        };

        const response = await Axios.delete(url, axiosOptions);

        handleRefreshAccessToken(response);

        const responseData = response.data as TResponse;

        return {
            isError: false,
            statusCode: response.status,
            content: responseData
        };

    } catch (error) {
        return handleError<TResponse>(url, error);
    }
}