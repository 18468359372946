import { ProgramEditor } from './ProgramEditor';
import { ProgramEditorProvider } from './ProgramEditorContext/ProgramEditorProvider';

export { ProgramWeek } from './ProgramWeek';
export { ProgramWeekDay } from './ProgramWeekDay';
export { ProgramEditorSkeleton } from './ProgramEditorSkeleton';
export { ProgramEditorAssignModal } from './ProgramEditorAssignModal';

interface Props {
    programId: string;
}

const ProgramEditorContainer: React.FC<Props> = ({
    programId
}) => {
    return (
        <ProgramEditorProvider programId={programId}>
            <ProgramEditor />
        </ProgramEditorProvider>
    );
};

export default ProgramEditorContainer;