import clsx from "clsx";
import { useState } from "react";

// TODO: add alt prop

interface ImageProps {
    src: string;
    className: string;
}

export const Image: React.FC<ImageProps> = ({
    src,
    className
}) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <>
            {isLoading && <div className={clsx(className, isLoading && "skeleton-box")}></div>}
            <img src={src} className={clsx(className, isLoading && "hidden")} onLoad={() => setIsLoading(false)} />
        </>
    );
};