interface InputErrorProps {
    error?: string
}

export const InputError: React.FC<InputErrorProps> = ({
    error
}) => {

    if(!error) return null;

    return (
        <div className='text-sm text-red-500 mt-1 px-2 py-1 bg-red-100 rounded-md border border-red-300'>
            {error}
        </div>
    );
};