import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiUser, IApiUserUpdateDto } from "./models/User";

export const updateUserDetails = async (userId: string, values: IApiUserUpdateDto): Promise<HttpClientResponse<IApiUser>> => {
    const url = buildUrl(`/api/users/${userId}`);

    const response = await httpClient.putRequest<ApiStandardResponse<IApiUser>>(url, { ...values });
    
    return mapHttpClientResponse(response, m => m);
};

export const updateProfilePicture = async (updateProfilePictureDto: FormData): Promise<HttpClientResponse<IApiUser>> => {
    const url = buildUrl(`/api/profile-picture`);

    const response = await httpClient.putRequest<ApiStandardResponse<IApiUser>>(url, updateProfilePictureDto);
    
    return mapHttpClientResponse(response, m => m);
};