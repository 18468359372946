import clsx from "clsx";
import { Image } from '../Image';
import { useAuth } from "../../auth/AuthContext";
import { config } from "../../config";

interface AvatarProps {
    size?: "sm",
}

export const Avatar: React.FC<AvatarProps> = ({
    size
}) => {
    const { user } = useAuth();
    const { apiUri } = config;

    const initials = `${user?.firstname[0]}${user?.lastname[0]}`;
    
    return (
        <div className={clsx(
            size === "sm" && "w-12 h-12 text-lg",
            !size && "w-24 h-24 text-3xl",
            "rounded-full bg-blue-50 flex items-center justify-center font-medium text-blue-400 overflow-hidden"
            )}>
            {!user?.avatar &&
                <span>{initials}</span>
            }
            {user?.avatar &&
                <Image src={`${apiUri}/api/file/${user.avatar}`} className="w-full h-full object-cover pointer-events-none" />
            }
        </div>
    );
};