import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { DropResult } from 'react-beautiful-dnd';
import { IApiGetProgram, IApiProgramDay, IApiProgramWeek, ICoreProgramDay, ICoreProgramWeek, MEASUREMENT_TYPE, MEASUREMENT_UNIT, WorkoutSet, WorkoutStep } from '../../../api/models/Program';
import { getWorkoutProgram, updateWorkoutProgram } from '../../../api/program';
import { ProgramEditorSkeleton } from '../.';
import { ProgramEditorContext } from "./ProgramEditorContext";
import { array_move } from '../../../utils/arrayUtils';
import { useExercise } from '../../../exercise/ExerciseContext';
import { Category } from '../../../api/models/Exercise';
import { Loader } from '../../Loader';

interface Props {
    programId: string;
}

export enum DnDType {
    WEEK = 'WEEK',
    WORKOUT_STEP = 'WORKOUT_STEP'
}

export type SetKeys = 'reps' | 'weight' | 'rpe' | 'percentage' | 'time' | 'speed' | 'distance' | 'measurementType' | 'units' | 'intervalOn' | 'intervalOff' | 'intervalRounds';

const newProgramWeek = (programId: string, order: number): ICoreProgramWeek => ({
    notes: '',
    order,
    programId,
    programDays: []
});

const newProgramDay = (dayNo: number, programWeekId: string): ICoreProgramDay => ({
    dayNo,
    programWeekId,
    workoutSteps: []
});

const newProgramSet = (isCardio?: boolean): WorkoutSet => {
    return {
        workoutSetId: uuidv4(),
        order: 0,
        measurementType: isCardio ? MEASUREMENT_TYPE.CARDIO_TIME : MEASUREMENT_TYPE.WEIGHT,
        weight: {
            value: 0,
            units: MEASUREMENT_UNIT.METRIC
        },
        interval: {
            intOn: 0,
            intOff: 0,
            rounds: 1
        },
        reps: 0,
        rpe: 0,
        percentage: 0,
        time: 0
    };
};

const newProgramStep = (exerciseId: string, order: number, parentStepId?: string, isCardio?: boolean): WorkoutStep => {
    return {
        workoutStepId: uuidv4(),
        exercise: exerciseId,
        order,
        sets: [newProgramSet(isCardio)],
        parentStepId: parentStepId
    };
};

export const ProgramEditorProvider: React.FC<Props> = ({
    programId,
    children
}) => {
    const { getExerciseById } = useExercise();

    const [edit, setEdit] = useState<IApiProgramDay>();
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [notFound, setNotfound] = useState(false);
    const [localOperationLoading, setLocalOperationLoading] = useState(false);

    const PROGRAM_KEY = `programId-${programId}`;

    const { isLoading, error, data, refetch } = useQuery(PROGRAM_KEY, async () => {
        setNotfound(false);

        const result = await getWorkoutProgram(programId);

        if(result.isError) {
            setNotfound(true);
        }

        if(!result.isError) {
            return result.content;
        }   
    });
    
    const updateProgramData = useMutation(JSON.stringify(data), async (updatedProgram: IApiGetProgram) => {
        setUnsavedChanges(false);
        const result = await updateWorkoutProgram(programId, updatedProgram);
        if(!result.isError) {
            refetch();
            setEdit(undefined);
            setLocalOperationLoading(false);
            return result.content;
        }
    });


    const getProgramDay = (dayNo: number, programWeekId: string): IApiProgramDay | null => {
        const programDay = data?.programDays.find(day => day.dayNo === dayNo && day.programWeekId === programWeekId);

        return programDay ?? null;
    };

    const editProgramDay = (dayNo?: number, programWeekId?: string): void => {
        if(typeof(dayNo) === "number" && programWeekId) {
            const programDay = data?.programDays.find(day => day.dayNo === dayNo && day.programWeekId === programWeekId);

            if(programDay) {
                setEdit(JSON.parse(JSON.stringify(programDay)));
            }

            if(!programDay) {
                const newDay = newProgramDay(dayNo, programWeekId)
                setEdit(JSON.parse(JSON.stringify(newDay)));
            }
        }

        if(typeof(dayNo) !== "number") {

            if(unsavedChanges) {
                const discard = window.confirm("Do you want to discard unsaved changes?");
                
                if(discard) {
                    discardWorkoutUpdates();
                }
            }

            if(!unsavedChanges) {
                setEdit(undefined);
            }
        }
    };

    const reOrderSets = (sets: WorkoutSet[]): WorkoutSet[] => {
        return sets.map((set, i) => ({ ...set, order: i }));
    };

    const reOrderWeeks = (weeks: IApiProgramWeek[]): IApiProgramWeek[] => {
        return weeks.map((week, i) => ({ ...week, order: i }));
    };

    const editProgramDayMeta = (name: string) => {
        if (edit) {
            setUnsavedChanges(true);

            setEdit({
                ...edit,
                name
            });
        }
    }

    const editProgramSet = (workoutStepId: string, workoutSetId: string, key: SetKeys, value: number) => {
        if(edit) {
            setUnsavedChanges(true);
            const day = Object.assign({}, edit);

            day.workoutSteps.forEach(workoutStep => {
                if(workoutStep.workoutStepId === workoutStepId) {
                    workoutStep.sets.forEach(set => {
                        if(set.workoutSetId === workoutSetId) {
                            if(key === 'weight') {
                                if(set.weight) {
                                    set.weight.value = value;
                                }

                                if(!set.weight) {
                                    set.weight = {
                                        value: value,
                                        units: MEASUREMENT_UNIT.METRIC
                                    }
                                }
                            } else if (key === 'units') {
                                if(set.weight) {
                                    set.weight.units = value;
                                }

                                if(!set.weight) {
                                    set.weight = {
                                        value: 0,
                                        units: value
                                    }
                                }
                            } else if (key === 'intervalOn') {
                                if(set.interval) {
                                    set.interval.intOn = value;
                                }

                                if(!set.interval) {
                                    set.interval = {
                                        intOn: value,
                                        intOff: 0,
                                        rounds: 1
                                    }
                                }
                            } else if (key === 'intervalOff') {
                                if(set.interval) {
                                    set.interval.intOff = value;
                                }

                                if(!set.interval) {
                                    set.interval = {
                                        intOn: 0,
                                        intOff: value,
                                        rounds: 1
                                    }
                                }
                            } else if (key === 'intervalRounds') {
                                if(set.interval) {
                                    set.interval.rounds = value;
                                }

                                if(!set.interval) {
                                    set.interval = {
                                        intOn: 0,
                                        intOff: 0,
                                        rounds: value
                                    }
                                }
                            } else {
                                set[key] = value;
                            }
                        }
                    });
                }
            });

            setEdit(day);
        }
    };

    const addWeek = (programWeekId?: string) => {
        if(data) {
            setLocalOperationLoading(true);
            const weeks = JSON.parse(JSON.stringify(data.programWeeks));
            const days = JSON.parse(JSON.stringify(data.programDays));
            
            if(!programWeekId) {
                weeks.push(newProgramWeek(programId, data.programWeeks.length ?? 0));
            }

            if(programWeekId) {
                let weekCopy = weeks.find((week: IApiProgramWeek) => week._id === programWeekId);
                
                if(weekCopy) {
                    weekCopy = JSON.parse(JSON.stringify(weekCopy));
                    weekCopy._id = undefined;
                    weekCopy.order = data.programWeeks.length;
                }

                if(!weekCopy) {
                    weeks.push(newProgramWeek(programId, data.programWeeks.length ?? 0));
                }

                const programDaysToCopy = days.filter((programDay: IApiProgramDay) => programDay.programWeekId === programWeekId);

                if(programDaysToCopy.length > 0) {
                    for(const programDay of programDaysToCopy) {
                        days.push({ ...programDay, _id: undefined, programWeekId: undefined });
                    }
                }

                weeks.push(weekCopy);
            }
    
            const updateProgramDto = {
                program: data.program,
                programWeeks: weeks,
                programDays: days
            };
    
            updateProgramData.mutate(updateProgramDto);
        }
    };

    const addExercise = (exerciseId: string, destinationIndex?: number) => {
        setUnsavedChanges(true);
        const exercise = getExerciseById(exerciseId);
        const day = Object.assign({}, edit);

        day.workoutSteps.push(newProgramStep(exerciseId, day.workoutSteps.length, undefined, exercise?.category === Category.cardio));

        if(typeof(destinationIndex) === 'number') {
            const reOrderedWorkoutSteps = array_move(day.workoutSteps, day.workoutSteps.length - 1, destinationIndex);

            reOrderedWorkoutSteps.forEach((workoutStep, i) => {
                workoutStep.order = i;
            });

            day.workoutSteps = reOrderedWorkoutSteps;
        }

        setEdit(day);
    };

    const addExercises = (selection: string[], workoutStepId?: string) => {
        selection.map(exercise => {
            workoutStepId ? addSuperset(exercise, workoutStepId) : addExercise(exercise);
        });
    };

    const removeExercise = (workoutStepId: string) => {
        setUnsavedChanges(true);
        const day = Object.assign({}, edit);
        const order = day.workoutSteps.find(workoutStep => workoutStep.workoutStepId === workoutStepId)?.order;

        day.workoutSteps = day.workoutSteps.filter(workoutStep => workoutStep.workoutStepId !== workoutStepId);

        const supersetSteps = day.workoutSteps.filter(workoutStep => workoutStep.parentStepId === workoutStepId);

        if(supersetSteps.length > 0) {
            const newSupersetParentId = supersetSteps[0].workoutStepId;

            day.workoutSteps.map(workoutStep => {
                if(workoutStep.workoutStepId === newSupersetParentId) {
                    workoutStep.parentStepId = undefined;
                    workoutStep.order = order ?? day.workoutSteps.length - 1;
                }

                if(workoutStep.parentStepId === workoutStepId) {
                    workoutStep.parentStepId = newSupersetParentId;
                }
            });
        }

        setEdit(day);
    };

    const addSet = (workoutStepId: string) => {
        setUnsavedChanges(true);
        const day = Object.assign({}, edit);

        day.workoutSteps.forEach(workoutStep => {
            if(workoutStep.workoutStepId === workoutStepId) {
                const setCount = workoutStep.sets.length;
                const prevSetCopy: WorkoutSet = JSON.parse(JSON.stringify(workoutStep.sets[setCount - 1]));

                prevSetCopy.workoutSetId = uuidv4();
                prevSetCopy.order = setCount;

                workoutStep.sets.push(prevSetCopy);
            }
        });

        setEdit(day);
    };

    const addSuperset = (exerciseId: string, workoutStepId: string) => {
        setUnsavedChanges(true);
        const day = Object.assign({}, edit);
        const exercise = getExerciseById(exerciseId);

        const parentWorkoutStep = day.workoutSteps.find(workoutStep => workoutStep.workoutStepId === workoutStepId);
        const existingSupersetSteps = day.workoutSteps.filter(workoutStep => workoutStep.parentStepId === workoutStepId);

        if(parentWorkoutStep) {
            const setCount = parentWorkoutStep.sets.length - 1;
            const supersetStep = newProgramStep(exerciseId, -1, workoutStepId, exercise?.category === Category.cardio);

            if(setCount > 0) {
                for(let i = 0; i < setCount; i++) {
                    supersetStep.sets.push(newProgramSet());
                }
            }

            day.workoutSteps.push(supersetStep);
        }

        setEdit(day);
    }

    const removeSet = (workoutStepId: string, workoutSetId: string) => {
        setUnsavedChanges(true);
        const day = Object.assign({}, edit);
        let deleteWorkoutStep = false;

        day.workoutSteps.forEach(workoutStep => {
            if(workoutStep.workoutStepId === workoutStepId) {
                workoutStep.sets = reOrderSets(workoutStep.sets.filter(set => set.workoutSetId !== workoutSetId));

                if(workoutStep.sets.length === 0) {
                    deleteWorkoutStep = true;
                }
            }
        });

        if(deleteWorkoutStep) {
            day.workoutSteps = day.workoutSteps.filter(workoutStep => workoutStep.workoutStepId !== workoutStepId);
        }

        setEdit(day);
    };

    const saveProgramDay = async () => {
        if(edit && data?.program && data.programWeeks) {
            const programDays: IApiProgramDay[] = JSON.parse(JSON.stringify(data?.programDays));

            const days = programDays.map(programDay => {
                if(programDay._id === edit?._id) {
                    return {...edit};
                } else {
                    return programDay;
                }
            });

            if(!edit._id) {
                days.push(edit);
            }

            const updateProgramDto = {
                program: data.program,
                programWeeks: data.programWeeks,
                programDays: days
            };

            updateProgramData.mutate(updateProgramDto);
        }
    };

    const deleteProgramDay = async (id: string) => {
        if(data) {
            const updateProgramDto = {
                program: data.program,
                programWeeks: data.programWeeks,
                programDays: data.programDays.filter(programDay => programDay._id !== id),
                programDaysToDelete: [id]
            };

            updateProgramData.mutate(updateProgramDto);
        }
    };

    const deleteProgramWeek = async (id: string) => {
        if(data) {
            const updateProgramDto = {
                program: data.program,
                programWeeks: reOrderWeeks(data.programWeeks.filter(programWeek => programWeek._id !== id)),
                programDays: data.programDays,
                programDaysToDelete: [],
                programWeeksToDelete: [id]
            };

            updateProgramData.mutate(updateProgramDto);
        }
    };

    const handleReOrderWeeks = (sourceIndex: number, destinationIndex: number) => {
        if(data) {
            const reOrderedProgramWeeks: IApiProgramWeek[] = array_move(data.programWeeks, sourceIndex, destinationIndex);

            reOrderedProgramWeeks.forEach((programWeek, i) => {
                programWeek.order = i;
            });

            const updateProgramDto = {
                program: data.program,
                programWeeks: reOrderedProgramWeeks,
                programDays: data.programDays
            };

            updateProgramData.mutate(updateProgramDto);
        }
    };

    const handleReOrderWorkoutSteps = (sourceIndex: number, destinationIndex: number) => {
        if(edit && data) {
            const reOrderedWorkoutSteps = array_move(edit.workoutSteps, sourceIndex, destinationIndex);

            reOrderedWorkoutSteps.forEach((workoutStep, i) => {
                workoutStep.order = i;
            });

            setUnsavedChanges(true);
            setEdit({
                ...edit,
                workoutSteps: reOrderedWorkoutSteps
            });
        }
    };

    const handleDragAndDrop = (result: DropResult) => {
        if(result.type === DnDType.WEEK) {
            if(result.destination) {
                handleReOrderWeeks(result.source.index, result.destination.index);
            }
        }

        if(result.type === DnDType.WORKOUT_STEP) {
            if (result.source.droppableId === "editor-exercise-list" && result.destination?.droppableId === "editor-workout-steps") {
                addExercise(result.draggableId, result.destination.index);
            }
            if (result.source.droppableId === "editor-workout-steps" && result.destination?.droppableId === "editor-workout-steps") {
                if(result.destination) {
                    handleReOrderWorkoutSteps(result.source.index, result.destination.index);
                }
            }
        }
    };

    const discardWorkoutUpdates = () => {
        setUnsavedChanges(false);
        setEdit(undefined);
    };

    return (
        <ProgramEditorContext.Provider value={{
            program: data?.program,
            programWeeks: data?.programWeeks,
            programDays: data?.programDays,
            clientProgram: data?.clientProgram,
            edit,
            isUpdating: updateProgramData.isLoading,
            notFound,
            getProgramDay,
            editProgramDay,
            editProgramSet,
            editProgramDayMeta,
            saveProgramDay,
            deleteProgramDay,
            deleteProgramWeek,
            addWeek,
            addExercise,
            addExercises,
            removeExercise,
            addSet,
            addSuperset,
            removeSet,
            handleDragAndDrop
        }}>
            {isLoading &&
                <ProgramEditorSkeleton />
            }
            {localOperationLoading &&
                <div className="fixed inset-0 flex items-center justify-center">
                    <Loader variant='Primary' />
                </div>
            }
            {!isLoading &&
                <>
                    {children}
                </>
            }
        </ProgramEditorContext.Provider>
    );
};