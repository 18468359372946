import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { FiMoreVertical, FiFileText } from 'react-icons/fi';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Modal } from '../Modal';
import { Button } from '../Buttons';
import { useWorkout } from '../../workout/WorkoutContext';
import { WorkoutLoggerExerciseInformation } from '.';

interface Props {
    activeStep: number;
    exercises: string[];
}

export const WorkoutLoggerStepMenu: React.FC<Props> = ({
    activeStep,
    exercises
}) => {
    const [noteModal, setNoteModal] = useState(false);
    const [exerciseInfoModal, setExerciseInfoModal] = useState(false);
    const { saveNotes, workoutStepNotes } = useWorkout();

    const [note, setNote] = useState("");

    const saveWorkoutStepNote = () => {
        saveNotes(note);
        setNoteModal(false);
    };

    useEffect(() => {
        setNote(workoutStepNotes.find(note => note.step === activeStep)?.notes ?? "");
    }, [activeStep]);

    return (
        <>
        <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                <FiMoreVertical
                    className="w-5 h-5"
                    aria-hidden="true"
                    />
                <span className="sr-only">Options</span>
            </Menu.Button>
            </div>
            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => setExerciseInfoModal(true)}
                        >
                            <BsFillQuestionCircleFill 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Exercise instructions
                        </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => setNoteModal(true)}
                        >
                            <FiFileText 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Add notes
                        </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
        </div>
        <Modal isOpen={noteModal} closeModal={() => setNoteModal(false)} title="Add notes">
            <textarea
                className="h-44 w-full border border-gray-200 px-4 py-2 pr-12 rounded-md"
                placeholder="Enter notes here..." value={note} onChange={(e) => setNote(e.currentTarget.value)}></textarea>
            <div className='mt-4 grid grid-cols-2 gap-4'>
                <Button type='button' variant='transparent' onClick={() => setNoteModal(false)}>Cancel</Button>
                <Button type='button' onClick={() => saveWorkoutStepNote()}>Save</Button>
            </div>
        </Modal>
        <WorkoutLoggerExerciseInformation open={exerciseInfoModal} closeModal={() => setExerciseInfoModal(false)} exercises={exercises} />
        </>
    );
};