import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { ClientDetailProgramItem } from "./ClientDetailProgramItem";
import { IApiGetClientProgramSchedule } from "../../../../api/models/Program";
import { FiEdit } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { AppPathsAdmin } from "../../../../routes/AppRoutes";
import { IconButton } from "../../../../components/Buttons";

interface ClientDetailProgramContentProps {
    scheduleData: IApiGetClientProgramSchedule
}

export const ClientDetailProgramContent: React.FC<ClientDetailProgramContentProps> = ({
    scheduleData
}) => {
    const history = useHistory();
    const [selectedWeek, setSelectedWeek] = useState<string>();

    const { clientPrograms, programs, programWeeks, programDays, programDayLogs } = scheduleData;

    const clientProgram = clientPrograms[0];

    const program = programs.find(program => program._id === clientProgram.program);

    const weeks = programWeeks.filter(week => week.programId === program?._id);

    const days = programDays.filter(day => weeks.map(week => week._id).includes(day.programWeekId));

    const start = DateTime.fromJSDate(new Date(clientProgram.startDate));
    const today = DateTime.fromJSDate(new Date());
    const weekDiff = Math.ceil(today.diff(start, "weeks").weeks) - 1;

    useEffect(() => {
        if(!selectedWeek) {
            setSelectedWeek(weeks[weekDiff]._id);
        }
    }, [weeks]);
    
    return (
        <>
        <div className="flex gap-6 w-full mb-6">
                <div className="flex-1">
                    <div className="flex items-center h-16 sticky top-20 lg:relative lg:top-0">
                        <h2 className="text-2xl font-medium">Current Program</h2>
                        <div className="ml-auto flex items-center">
                            <IconButton label="Edit program" onClick={() => history.push(AppPathsAdmin.workoutPlanEdit(scheduleData.programs[0]._id))}>
                                <FiEdit />
                            </IconButton>
                        </div>
                        <div className="ml-4">
                            <select value={selectedWeek} onChange={(e) => setSelectedWeek(e.currentTarget.value)} className="h-12 px-4 rounded-md border border-gray-200 bg-transparent">
                                {weeks.map(week => <option value={week._id} key={week._id}>Week {week.order + 1}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="pt-4">
                        {weeks.filter(week => selectedWeek ? week._id === selectedWeek : week).map(week => {
                            return (
                                <>
                                    {days.filter(day => day.programWeekId === week._id).map((day) => 
                                        <ClientDetailProgramItem day={day} week={week} clientProgram={clientProgram} programLog={programDayLogs.find(log => log.programDay === day._id)?._id} key={day._id} />
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="flex-none w-full max-w-sm bg-white rounded-md hidden lg:block">
                    <div className="flex items-center px-6 h-16 border-b border-gray-200">
                        <h3 className="text-2xl font-medium">Details</h3>
                    </div>
                    <div className="px-6 py-6">
                        <h3 className="text-lg font-medium">{program?.name}</h3>
                        <p>{program?.description}</p>
                        <p className="mt-4"><span className="font-medium">Start date: </span>{DateTime.fromISO(clientProgram.startDate).toFormat("dd MMM yyyy")}</p>
                        <p className="mt-4"><span className="font-medium">Current week: </span>Week {weekDiff + 1}</p>
                    </div>
                </div>
            </div>
        </>
    );
};