import { IUser } from "../models/User";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IAddClientDto } from "./models/Clients";
import { IApiUser } from "./models/User";

export const getVendorClients = async (): Promise<HttpClientResponse<IUser[]>> => {
    const url = buildUrl('/api/clients');

    const response = await httpClient.getRequest<ApiListResponse<IApiUser>>(url);
    
    return mapHttpListResponse(response, m => m);
};

export const getVendorClient = async (id: string): Promise<HttpClientResponse<IUser>> => {
    const url = buildUrl(`/api/clients/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiUser>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const addVendorClient = async (client: IAddClientDto): Promise<HttpClientResponse<IApiUser>> => {
    const url = buildUrl('/api/clients');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiUser>>(url, client);

    return mapHttpClientResponse(response, m => m);
};

export const archiveVendorClient = async (id: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/archive/clients/${id}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const deleteVendorClient = async (id: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/clients/${id}`);

    const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const clientPasswordConfirmation = async (confirmationCode: string, password: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/clients/confirm/${confirmationCode}`);

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, { password });

    return mapHttpClientResponse(response, m => m);
};