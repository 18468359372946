import { DateTime } from "luxon";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse } from "./httpClient/mapHttpClientResponse";
import { IApiGetClientProgramSchedule, IApiGetProgram, IApiGetUpcomingClientPrograms, IApiProgram, IApiProgramDay, ICoreProgram } from "./models/Program";

export const createWorkoutProgram = async (program: ICoreProgram): Promise<HttpClientResponse<IApiProgram>> => {
    const url = buildUrl('/api/program');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiProgram>>(url, program);

    return mapHttpClientResponse(response, m => m);
};

export const getWorkoutProgram = async (programId: string): Promise<HttpClientResponse<IApiGetProgram>> => {
    const url = buildUrl(`/api/program/${programId}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetProgram>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const updateWorkoutProgram = async (programId: string, updatedProgram: IApiGetProgram): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/${programId}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, updatedProgram);

    return mapHttpClientResponse(response, m => m);
};

export const assignProgramToClient = async (program: string, client: string, startDate: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/assign`);
    const dto = {
        program, client, startDate
    };

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, dto);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramSchedule = async (): Promise<HttpClientResponse<IApiGetClientProgramSchedule>> => {
    const currentDate = DateTime.fromJSDate(new Date()).toString();
    const url = buildUrl(`/api/program/schedule/me/${currentDate}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetClientProgramSchedule>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramScheduleByClientId = async (id: string): Promise<HttpClientResponse<IApiGetClientProgramSchedule>> => {
    const url = buildUrl(`/api/program/schedule/client/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetClientProgramSchedule>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getUpcomingClientProgramsByClientId = async (id: string): Promise<HttpClientResponse<IApiGetUpcomingClientPrograms>> => {
    const url = buildUrl(`/api/program/upcoming/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetUpcomingClientPrograms>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramDay = async (id: string): Promise<HttpClientResponse<IApiProgramDay>> => {
    const url = buildUrl(`/api/program/day/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiProgramDay>>(url);

    return mapHttpClientResponse(response, m => m);
};