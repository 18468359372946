import clsx from "clsx";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { FiClock, FiX } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ICoreProgramLogItem, IProgramLogNote, MEASUREMENT_UNIT } from "../api/models/Program";
import { IApiCreateProgramLogDto } from "../api/models/ProgramLog";
import { AppPathsClient } from "../routes/AppRoutes";
import { WorkoutContext } from "./WorkoutContext";
import { createProgramLog } from "../api/programLog";
import { Button, IconButton } from "../components/Buttons";
import { Modal } from "../components/Modal";
import { TimerModal } from "../components/TimerModal";
import { IntervalTimerModal } from "../components/IntervalTimerModal";
import { IntervalObject } from "../components/IntervalTimerModal/IntervalTimerModal";

export enum WorkoutNav {
    PREV,
    NEXT
}

export type setLogKey = "reps" | "weight";

export const WorkoutProvider: React.FC = ({
    children
}) => {
    const history = useHistory();

    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [programDayId, setProgramDayId] = useState<string>();
    const [steps, setSteps] = useState<number>();
    const [activeStep, setActiveStep] = useState<number>();
    const [timerStartTime, setTimerStartTime] = useState<Date>();
    const [timer, setTimer] = useState<string>();
    const [workoutSetLogs, setWorkoutSetLogs] = useState<ICoreProgramLogItem[]>([]);
    const [workoutStepNotes, setWorkoutStepNotes] = useState<IProgramLogNote[]>([]);
    const [timerModal, setTimerModal] = useState<number>();
    const [intervalTimerModal, setIntervalTimerModal] = useState<IntervalObject>();

    const persistWorkout = () => {
        programDayId && localStorage.setItem('programDayId', programDayId);
        typeof(activeStep) === 'number' && localStorage.setItem('activeStep', activeStep.toString());
        timerStartTime && localStorage.setItem('timerStartTime', timerStartTime.toISOString());
        workoutSetLogs.length > 0 && localStorage.setItem('workoutSetLogs', JSON.stringify(workoutSetLogs));
        workoutStepNotes.length > 0  && localStorage.setItem('workoutStepNotes', JSON.stringify(workoutStepNotes));
    };

    const loadPersistedWorkout = () => {
        const persistedProgramDayId = localStorage.getItem('programDayId');
        const persistedActiveStep = localStorage.getItem('activeStep');
        const persistedTimerStartTime = localStorage.getItem('timerStartTime');
        const persistedWorkoutSetLogs = localStorage.getItem('workoutSetLogs');
        const persistedWorkoutStepNotes = localStorage.getItem('workoutStepNotes');

        persistedProgramDayId && setProgramDayId(persistedProgramDayId);
        persistedActiveStep && setActiveStep(parseInt(persistedActiveStep));
        persistedTimerStartTime && setTimerStartTime(new Date(persistedTimerStartTime));
        persistedWorkoutSetLogs && setWorkoutSetLogs(JSON.parse(persistedWorkoutSetLogs));
        persistedWorkoutStepNotes && setWorkoutStepNotes(JSON.parse(persistedWorkoutStepNotes));
    };

    const clearPersistedWorkout = () => {
        localStorage.removeItem('activeStep');
        localStorage.removeItem('timerStartTime');
        localStorage.removeItem('workoutSetLogs');
        localStorage.removeItem('workoutStepNotes');
        localStorage.removeItem('programDayId');
    };

    const initializeWorkout = (programDayIdInit: string, steps: number, activeStep: number) => {
        if(!programDayId) {
            setProgramDayId(programDayIdInit);
            setSteps(steps);
            setActiveStep(activeStep);
            setTimerStartTime(new Date());
        }
    };

    const cancelWorkout = () => {
        setTimerStartTime(undefined);
        setTimer(undefined);
        setProgramDayId(undefined);
        setSteps(undefined);
        setActiveStep(undefined);
        setWorkoutSetLogs([]);
        setWorkoutStepNotes([]);
        setTimer(undefined);
        clearPersistedWorkout();
    };

    const logSet = (workoutSetId: string, key: setLogKey, val: number, exercise: string, units: MEASUREMENT_UNIT) => {
        const logItemList: ICoreProgramLogItem[] = JSON.parse(JSON.stringify(workoutSetLogs));

        const logItemToEdit = logItemList.find(item => item.workoutSetId === workoutSetId);

        if(logItemToEdit) {
            logItemToEdit[key] = val;
        } else {
            logItemList.push({
                workoutSetId,
                exercise,
                [key]: val,
                units
            });
        }

        setWorkoutSetLogs(logItemList);
    };

    const getSet = (workoutSetId: string): ICoreProgramLogItem | undefined => {
        return workoutSetLogs.find(workoutSet => workoutSet.workoutSetId === workoutSetId);
    };

    const saveNotes = (note: string) => {
        const noteList: IProgramLogNote[] = JSON.parse(JSON.stringify(workoutStepNotes));

        const existingNote = noteList.find(note => note.step === activeStep);

        if(existingNote) {
            existingNote.notes = note;
        } else {
            noteList.push({
                step: activeStep ?? 0,
                notes: note
            });
        }

        setWorkoutStepNotes(noteList);
    };

    const navigateWorkout = (direction: WorkoutNav) => {
        if(typeof activeStep === "number" && programDayId) {
            if(direction === WorkoutNav.NEXT && activeStep !== steps) {
                const step = activeStep + 1;
                setActiveStep(step);
                history.push(AppPathsClient.workout(programDayId, step.toString()));
            }
    
            if(direction === WorkoutNav.PREV && activeStep !== 0) {
                const step = activeStep - 1;
                setActiveStep(step);
                history.push(AppPathsClient.workout(programDayId, step.toString()));
            }
        }
    };

    const saveProgramLogRequest = useMutation(`${programDayId}-log`, async (createProgramLogDto: IApiCreateProgramLogDto) => {
        const result = await createProgramLog(createProgramLogDto);
        if(!result.isError) {
            setCancelled(true);

            history.push(AppPathsClient.workoutComplete(result.content._id));
        } 
    });

    const saveProgramLog = async (program: string) => {
        const dto: IApiCreateProgramLogDto = {
            program,
            programDay: programDayId ?? '',
            start: timerStartTime ?? new Date(),
            programLogItems: workoutSetLogs,
            notes: workoutStepNotes
        };

        saveProgramLogRequest.mutate(dto);
    };

    const initializeTimer = (duration: number) => {
        setTimerModal(duration);
    };

    const initializeIntervalTimer = (intervalObj: IntervalObject) => {
        setIntervalTimerModal(intervalObj);
    };

    useEffect(() => {
        if(programDayId && timerStartTime) {
            const timeInterval = setInterval(() => {
                const diff = DateTime.fromJSDate(new Date()).diff(DateTime.fromJSDate(timerStartTime)).toFormat("hh:mm:ss");

                setTimer(diff);
            }, 500);

            return () => clearInterval(timeInterval);
        }
    }, [programDayId]);

    useEffect(() => {
        if(cancelled) {
            cancelWorkout();
            setCancelled(false);
            setCancelConfirmation(false);
        }
    }, [cancelled]);

    useEffect(() => {
        persistWorkout();
    }, [programDayId, activeStep, workoutSetLogs, workoutStepNotes]);

    useEffect(() => {
        loadPersistedWorkout();
    }, []);

    const displayResumeWorkoutPopover = (history.location.pathname.split("/")[1] !== "workout") && programDayId;

    return (
        <WorkoutContext.Provider value={{
            initializeWorkout,
            cancelWorkout,
            navigateWorkout,
            logSet,
            getSet,
            saveProgramLog,
            saveNotes,
            initializeTimer,
            initializeIntervalTimer,
            workoutStepNotes,
            timer
        }}>
            {children}
            <TimerModal duration={timerModal} onClose={() => setTimerModal(undefined)} />
            <IntervalTimerModal interval={intervalTimerModal} onClose={() => setIntervalTimerModal(undefined)} />
            {displayResumeWorkoutPopover &&
                <>
                    <div className={clsx("fixed bottom-4 right-4 rounded-md bg-blue-500 text-white p-4 flex items-center shadow-soft")}>
                        <FiClock className="w-6 h-6 mr-3" />
                        <span>{timer}</span>
                        <Link 
                            to={AppPathsClient.workout(programDayId ?? '', activeStep?.toString() ?? '0')}
                            className="ml-3 px-2 py-1 rounded-md text-sm uppercase font-medium bg-white bg-opacity-20">
                                Resume
                        </Link>
                        <button className="p-2 ml-4 rounded-md bg-black bg-opacity-5" onClick={() => setCancelConfirmation(true)}>
                            <FiX />
                        </button>
                    </div>
                    <Modal 
                        isOpen={cancelConfirmation} 
                        closeModal={() => setCancelConfirmation(false)} 
                        title='Cancel workout' 
                        caption={`Are you sure you want to cancel workout that is in progress?`}>
                            <div className='mt-4 grid grid-cols-2 gap-4'>
                                <Button type='button' variant='transparent' onClick={() => setCancelConfirmation(false)}>No</Button>
                                <Button type='button' variant='danger' onClick={() => setCancelled(true)}>Yes, Cancel</Button>
                            </div>
                    </Modal>
                </>
            }
        </WorkoutContext.Provider>
    )
};