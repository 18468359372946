import { IApiActivity } from "../../api/models/Activity";
import { ActivityType } from "../../api/models/Activity";
import { AvatarThirdParty } from "../../components/Avatar";
import { FiActivity, FiCheckCircle, FiDollarSign, FiFileText, FiList } from "react-icons/fi";
import { MdFitnessCenter } from "react-icons/md";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { AppPathsAdmin, AppPathsClient } from "../../routes/AppRoutes";

interface ActivityItemProps {
    activity: IApiActivity;
}

const ActivityLink: React.FC<ActivityItemProps> = ({
    activity,
    children
}) => {
    let linkPath = '/';

    switch(activity.type) {
        case ActivityType.WORKOUT_LOG:
            linkPath = AppPathsAdmin.clientLogbookItem(activity.user._id, activity.activityId);
            break;
        default:
            linkPath = '/';
            break;
    }


    return (
        <Link to={linkPath} className="flex items-center border-b border-gray-100 py-4">
            {children}
        </Link>
    );
};

export const ActivityItem: React.FC<ActivityItemProps> = ({
    activity
}) => {
    const formattedDate = DateTime.fromISO(activity.createdAt).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

    return (
        <li className="px-6 cursor-pointer transition-all hover:bg-blue-50" key={activity._id}>
            <ActivityLink activity={activity}>
                <div className="flex items-center justify-center rounded-md bg-blue-100 w-8 h-8 mr-4">
                    {activity.type === ActivityType.WORKOUT_LOG && <MdFitnessCenter className="w-4 h-4 text-blue-400" />}
                </div>
                <div className="">
                    <div className="text-blue-400 font-medium text-sm mb-1">
                        {activity.type === ActivityType.WORKOUT_LOG && "Workout complete"}
                    </div>
                    <div className="text-xxs font-medium text-gray-500">{formattedDate}</div>
                </div>
                <div className="ml-auto">
                    <AvatarThirdParty size="xxs" initials={`${activity.user.firstname[0]}${activity.user.lastname[0]}`} src={activity.user.avatar} />
                </div>
            </ActivityLink>
        </li>
    );
};