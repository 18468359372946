import { ChangeEvent, useState } from "react";
import { updateProfilePicture } from "../../api/user";
import { useAuth } from "../../auth/AuthContext";
import { Avatar } from "../Avatar";
import { RelativeLoader } from "../Loader";
import { toastNotification } from "../ToastNotification";

export const AvatarUpdateCard: React.FC = () => {
    const { updateUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleProfilePictureUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const formData = new FormData();

        if(e.currentTarget.files && e.currentTarget.files[0]) {
            formData.append('file', e.currentTarget.files[0]);

            const result = await updateProfilePicture(formData);

            if(result.isError) {
                toastNotification({ variant: 'error', title: 'Upload failed', message: 'There was a problem updating your profile picture. Please try again.'});
            }

            if(!result.isError) {
                updateUser(result.content);
                toastNotification({ variant: 'success', title: 'Profile Picture Updated', message: 'Your profile picture has been successfully updated.'});
            }
        }
        setIsLoading(false);
    };

    return (
        <div className="relative bg-white h-72 rounded-md flex items-center justify-center p-4">
            <div className="relative flex flex-col items-center justify-center rounded-md border-dashed border-2 border-transparent w-full h-full cursor-pointer transition-all hover:border-blue-100">
                <Avatar />
                <div className="mt-4 font-medium text-gray-500">Change profile picture</div>
                <input className="absolute top-0 inset-0 opacity-0 cursor-pointer" type="file" typeof="image" accept="image/gif, image/jpeg, image/png" onChange={handleProfilePictureUpload} />
            </div>
            <div className="absolute top-2 right-2 w-16 h-16">
                {isLoading &&
                    <RelativeLoader size="sm" />
                }
            </div>
        </div>
    );
};