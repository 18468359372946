import clsx from "clsx";
import { DateTime } from "luxon";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IApiClientProgram, IApiProgram } from "../../../../api/models/Program";
import { AppPathsAdmin } from "../../../../routes/AppRoutes";

interface ClientDetailProgramUpcomingProps {
    clientPrograms: IApiClientProgram[];
    programs: IApiProgram[];
}

export const ClientDetailProgramUpcoming: React.FC<ClientDetailProgramUpcomingProps> = ({
    clientPrograms,
    programs
}) => {
    return (
        <div className="mb-4">
            <h2 className="text-2xl font-medium mb-4">Upcoming Programs</h2>
            {clientPrograms.map(clientProgram => {
                const programDetail = programs.find(program => program._id === clientProgram.program);
                const date = new Date(clientProgram.startDate);
                const dt = DateTime.fromJSDate(date);

                return (
                    <div className="bg-white w-full flex items-center rounded-md p-4 mb-2 transition-all shadow-soft">
                        <div className={clsx("w-12 h-12 rounded-md bg-gray-50 flex flex-col items-center justify-center transition-all group-hover:bg-gray-500")}>
                            <span className={clsx("text-xxs font-bold uppercase -mb-1 group-hover:text-white")}>{dt.toFormat('MMM')}</span>
                            <span className={clsx("text-xl font-medium group-hover:text-white")}>{dt.toFormat('dd')}</span>
                        </div>
                        <div className="ml-4">
                            <div className="font-medium text-gray-500">{programDetail?.name}</div>
                        </div>
                        <div className="ml-auto">
                            <Link to={AppPathsAdmin.workoutPlanEdit(programDetail?._id ?? '')} className="flex items-center group">
                                <div className="flex items-center justify-center w-10 h-10 rounded-md transition-all bg-gray-600 group-hover:bg-gray-400">
                                    <FiEdit className="text-white" />
                                </div>
                                <span className="font-medium ml-3 transition-all group-hover:opacity-60">Edit program</span>
                            </Link>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};